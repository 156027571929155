import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { RootDataContext } from "../../../index"
import DouroDetailView from "./douro"
import KosatenDetailView from "./kosaten"
import JissekiDetailView from "./jisseki"
import APIManager from "../../../../../manager/api"

const styles = {
  root: {
    backgroundColor: "#f3f3f3",
    color: "black",
    padding: "12px",
  },
}

const MapDetailInfoCardView = (props) => {
  const { state } = useContext(RootDataContext)
  const [data, setData] = useState()
  const lastLoadUuidRef = useRef()
  const timerRef = useRef()

  const updateData = useCallback(() => {
    let func
    switch (state.mapDetailInfo.type) {
      case "douro":
        func = APIManager.getDouroDetailData
        break
      case "kosaten":
        func = APIManager.getKosatenDetailData
        break
      case "jisseki":
        func = APIManager.getJissekiDetailData
        break
      default:
        return
    }

    lastLoadUuidRef.current = state.mapDetailInfo.uuid
    func(state.mapDetailInfo.uuid, state.predictionMode)
      .then((res) => {
        console.log('[Detail Info Card]', 'data', res)
        setData(res)
      })
      .catch((e) => {
        console.log(e)
      })

    return () => {
      setData(null)
    }
  }, [
    state.predictionMode,
    state.mapDetailInfo?.uuid,
    state.mapDetailInfo?.type,
  ])

  useEffect(() => {
    if (
      !state.predictionMode ||
      !state.mapDetailInfo?.uuid ||
      !state.mapDetailInfo?.type
    ) {
      return
    }

    console.log("[Update Detail Info]")

    timerRef.current && clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      updateData()
    }, 1500)
  }, [
    state.predictionMode,
    state.mapDetailInfo?.uuid,
    state.mapDetailInfo?.type,
  ])

  return (
    <Box sx={{ ...styles.root, ...props.style }}>
      <Typography>詳細情報</Typography>
      {data && (
        <Box>
          {state.mapDetailInfo.type === "douro" && (
            <DouroDetailView data={data} />
          )}
          {state.mapDetailInfo.type === "kosaten" && (
            <KosatenDetailView data={data} />
          )}
          {state.mapDetailInfo.type === "jisseki" && (
            <JissekiDetailView data={data} />
          )}
        </Box>
      )}
      {!data && (
        <Box>
          <Typography>読み込み中...</Typography>
        </Box>
      )}
    </Box>
  )
}

MapDetailInfoCardView.propTypes = {
  style: PropTypes.object,
}

export default MapDetailInfoCardView
