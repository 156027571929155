import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  IconButton,
  TextareaAutosize,
  Typography,
} from "@mui/material"
import { useDropzone } from "react-dropzone"
import APIManager from "../../../../manager/api"
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  InsertPhoto as InsertPhotoIcon,
  Send as SendIcon,
} from "@mui/icons-material"
import { RootDataContext } from "../../index"
import { MemoDataContext } from "../index"
import { useAuth0 } from "@auth0/auth0-react"
import dayjs from "dayjs"

const styles = {
  root: { margin: "8px" },
  textArea: {
    overflow: "hidden auto",
    minHeight: "24px",
    maxHeight: "100%",
    margin: "0 8px",
    width: "calc(100% - 16px)",
  },
  dragBox: {
    position: "absolute",
    top: 0,
    left: "8px",
    backgroundColor: "#eee",
    width: "calc(100% - 16px)",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed #999",
  },
  dragLabelBox: {
    margin: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dragIcon: {
    color: "#999",
  },
  filePreviewListBox: {
    display: "flex",
    flexDirection: "row",
    margin: "0 8px",
  },
  filePreviewBox: {
    position: "relative",
    "&:hover": { border: "1px solid #000" },
    border: "1px solid #fff",
  },
  filePreviewImage: {
    height: "80px",
  },
  fileRemoveButton: {
    position: "absolute",
    top: "0",
    width: "24px",
    height: "24px",
    right: "0",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  },
}

const weekday = ["日", "月", "火", "水", "木", "金", "土"]

// eslint-disable-next-line react/display-name
const MemoCommentInputView = React.memo((props) => {
  const fileInputRef = useRef()
  const {
    state: memoState,
    dataUpdated,
    setThread,
  } = useContext(MemoDataContext)
  const { state, setCommentPreviewImage } = useContext(RootDataContext)
  const [message, setMessage] = useState()
  const [files, setFiles] = useState(props.data?.files)
  const [fileDataList, setFileDataList] = useState(null)
  const [uploadError, setUploadError] = useState()
  const [showTrashButtonIndex, setShowTrashButtonIndex] = useState()
  const [updated, setUpdated] = useState(dayjs().unix())
  const [edited, setEdited] = useState(false)
  const textAreaRef = useRef()

  useEffect(() => {
    setMessage(props.data?.message)
  }, [props.data?.message])

  const checkEdited = useCallback(() => {
    if (message !== props.data?.message) {
      return setEdited(true)
    }
    console.log(
      "Check edited",
      JSON.stringify(files ?? []),
      JSON.stringify(props.data?.files ?? [])
    )
    if (
      JSON.stringify(files ?? []) !== JSON.stringify(props.data?.files ?? [])
    ) {
      return setEdited(true)
    }

    return setEdited(false)
  }, [message, files, fileDataList, updated])

  //
  // const edited = useMemo(() => {
  //   if (!message) {
  //     return false
  //   }
  //   if (message !== props.data?.message) {
  //     return true
  //   }
  //   console.log('Check edited', JSON.stringify(files ?? []), JSON.stringify(props.data?.files ?? []))
  //   if (
  //     JSON.stringify(files ?? []) !== JSON.stringify(props.data?.files ?? [])
  //   ) {
  //     return true
  //   }
  //
  //   return false
  // }, [message, files, fileDataList, updated])

  const [uploadingFileCount, setUploadingFileCount] = useState(0)
  const [uploadingFileIndex, setUploadingFileIndex] = useState(0)

  const onDrop = useCallback(
    async (uploadFiles) => {
      let err = null
      setUploadingFileCount(uploadFiles.length)
      setUploadingFileIndex(0)

      try {
        let f = [...(files ?? [])]
        for (let i = 0; i < uploadFiles.length; i++) {
          setUploadingFileIndex(i)
          let fp = uploadFiles[i]
          if (fp.size > 10000000) {
            setUploadingFileCount(0)
            setUploadError("10MBを超えているファイルがあります")
            return
          }

          if (!fp.type.startsWith("image/")) {
            setUploadingFileCount(0)
            setUploadError("アップロードできるのは画像ファイルのみです")
            return
          }

          let res = await APIManager.uploadImage(fp).catch((e) => {
            setUploadingFileCount(0)
            setUploadError("ファイルアップロードに失敗しました")
            throw e
          })

          f.push(res)
        }

        setFiles(f)
        setEdited(true)
      } catch (e) {
        console.log(e)
      }
    },
    [files]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop,
    noClick: true,
  })

  useEffect(() => {
    if ((files ?? []).length === 0) {
      setFileDataList(null)
      return
    }

    Promise.all(
      files?.map((f) => {
        console.log(files)
        return new Promise((resolve, reject) => {
          APIManager.getImage(f.path)
            .then((res) => {
              let url = window.URL ?? window.webkitURL
              resolve({ src: url.createObjectURL(res), path: f.path })
            })
            .catch((e) => {
              reject(e)
            })
        })
      })
    )
      .then((result) => {
        console.log(result)
        setFileDataList(result)
        setUploadingFileCount(0)
        setUpdated(dayjs().unix())
      })
      .catch((e) => {
        alert("画像の取得に失敗しました")
      })
  }, [files])

  useEffect(() => {
    setTimeout(() => {
      setUploadError(null)
    }, 3000)
  }, [uploadError])

  useEffect(() => {
    clear()
  }, [memoState.update, state.thread, state.threadId])

  const onMessageChanged = (e) => {
    setMessage(e.target.value)
    checkEdited()
  }

  const clear = useCallback(() => {
    setMessage(null)
    setFiles(null)
    textAreaRef.current.value = ""
  }, [message, files, memoState.update])

  const onCommit = useCallback(() => {
    setEdited(false)
    if (props.data?.comment_id) {
      APIManager.updateMemoComment(
        props.data.comment_id,
        message ?? props.data?.message,
        files,
      )
        .then(() => {
          dataUpdated()
          textAreaRef.current.value = ""
          setFiles(null)
          props.onClose && props.onClose()
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      if (memoState.threadId) {
        APIManager.setMemoComment(memoState.threadId, message, files)
          .then((res) => {
            console.log("Memo", "add thread", memoState.threadId, res)
            dataUpdated()
            textAreaRef.current.value = ""
            setFiles(null)
            setEdited(false)
            props.onClose && props.onClose()
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        APIManager.setMemoThread(
          state.showMemoData.type,
          state.showMemoData.uuid,
          memoState.threadTitle ??
            dayjs().format("スレッド YYYY年M月D日 H時m分")
        ).then((thread) => {
          APIManager.setMemoComment(thread.thread_id, message, files)
            .then((res) => {
              console.log(res)
              setThread({...thread, is_editable: true})
              textAreaRef.current.value = ""
              setFiles(null)
              setEdited(false)
              props.onClose && props.onClose()
            })
            .catch((e) => {
              console.log(e)
            })
        })
      }
    }
  }, [props.data, message, files])

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Box {...getRootProps()} sx={{ position: "relative" }}>
        <input {...getInputProps()} />
        <TextareaAutosize
          ref={textAreaRef}
          style={styles.textArea}
          placeholder="メモ入力.."
          minRows={2}
          defaultValue={props.data?.message}
          onChange={onMessageChanged}
        />
        {isDragActive && (
          <Box sx={styles.dragBox}>
            <Box sx={styles.dragLabelBox}>
              <Typography>ここにドロップしてアップロード</Typography>
              <CloudUploadIcon style={styles.dragIcon} />
            </Box>
          </Box>
        )}
        {(uploadingFileCount ?? 0) > 0 && (
          <Box sx={styles.dragBox}>
            {uploadingFileCount > 1 && (
              <Typography>
                {uploadingFileCount}個中{uploadingFileIndex + 1}
                をアップロード...
              </Typography>
            )}
            {uploadingFileCount === 1 && (
              <Typography>アップロード中...</Typography>
            )}
          </Box>
        )}
      </Box>
      {uploadError && (
        <Typography
          style={{ color: "#f00", margin: "0 8px" }}
          variant="caption"
        >
          {uploadError}
        </Typography>
      )}
      <Box sx={styles.filePreviewListBox}>
        {fileDataList?.map((d, i) => {
          return (
            <Box
              key={d.path}
              sx={styles.filePreviewBox}
              onMouseEnter={() => setShowTrashButtonIndex(i)}
              onMouseLeave={() =>
                showTrashButtonIndex === i && setShowTrashButtonIndex(null)
              }
              onClick={() => {
                setCommentPreviewImage(d.src)
              }}
            >
              <img src={d.src} style={styles.filePreviewImage} />
              <button
                style={{
                  ...styles.fileRemoveButton,
                  ...(showTrashButtonIndex === i && { display: "flex" }),
                }}
              >
                <DeleteIcon
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    setFiles([...files.filter((d, idx) => idx !== i)])
                    setEdited(true)
                  }}
                />
              </button>
            </Box>
          )
        })}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", marginRight: "8px" }}>
        <IconButton
          size="small"
          onClick={() => {
            console.log(fileInputRef)
            fileInputRef.current?.click()
          }}
        >
          <InsertPhotoIcon />
        </IconButton>
        <input
          type="file"
          ref={fileInputRef}
          hidden={true}
          onChange={(e) => onDrop(Array.from(e.target.files))}
        />
        <Box sx={{ flexGrow: 1 }} />
        {props.data?.comment_id && (
          <Box>
            <Button
              style={{ color: "#f00" }}
              onClick={() => {
                props.onCancel && props.onCancel()
                props.onClose && props.onClose()
              }}
            >
              キャンセル
            </Button>
            <Button
              disabled={!edited}
              style={{ color: edited ? "#4545e1" : "#afafaf" }}
              onClick={onCommit}
            >
              変更を保存
            </Button>
          </Box>
        )}
        {!props.data?.comment_id && (
          <IconButton size="small" disabled={!edited} onClick={onCommit}>
            <SendIcon style={{ color: edited ? "#4545e1" : "#afafaf" }} />
          </IconButton>
        )}
      </Box>
    </Box>
  )
})

MemoCommentInputView.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
}

export default MemoCommentInputView
