import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import PropTypes from "prop-types"
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Modal,
  Select,
  setRef,
  Snackbar,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material"
import UseRootViewData, { ViewType } from "./data"
import NotificationView from "./notification"
import OptionView from "./option"
import LogoImage from "../../resources/logo.png"
import dayjs from "dayjs"
import MapContents from "./map"
import ListContents from "./list"
import ExportView from "./export"
import StateDialog from "./stateDialog"
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import RootPlaceSearchView from "./placeSearch"
import TermsOfServiceView from "../terms_of_service"
import RootHelpView from "./help"
import MemoView from "./memo"
import RootResetPasswordView from "./resetPassword"
import APIManager from "../../manager/api"
import ContactDialog from "./contact"

import { initializeApp } from "firebase/app"
import { getAnalytics, setUserId } from "firebase/analytics"
import RootCommentSearchView from "./commentSearch"
import { Download as DownloadIcon } from "@mui/icons-material"
import RootPredictionModeView from "./predictionMode"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// }

const styles = {
  root: {
    backgroundColor: "white",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    minWidth: "1600px",
    overflowX: "auto",
  },
  viewSwitchButton: {
    backgroundColor: "#c2c2c2 !important",
    borderRadius: "0 !important",
    color: "white !important",
    width: "165px !important",
    height: "35px !important",
    margin: "auto !important",
  },
  activeButton: {
    backgroundColor: "#3c6960 !important",
  },
  nativeButton: {
    background:
      "linear-gradient(0deg, rgba(117,110,231,1) 0%, rgba(29,29,170,1) 60%, rgba(209,209,255,1) 100%)",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    gap: "5px",
  },
  mainPanel: {},
  rightPanel: {
    width: "350px",
    height: "100%",
    margin: "0 8px",
  },
  placeSearch: {
    width: "300px",
  },
  commentSearch: {
    width: "300px",
  },
}

export const RootDataContext = React.createContext()

// eslint-disable-next-line react/display-name
const RootView = React.memo((props) => {
  console.log("RootView render with", props)

  const { user, logout, isLoading, getAccessTokenSilently } = useAuth0()
  const useRootData = UseRootViewData()
  const { state, setViewType, setUser, setCommentPreviewImage, setServerEnv } =
    useRootData
  const [notificationMessage, setNotificationMessage] = useState(null)
  //  const [refresh, setRefresh] = useState()
  const [showExport, setShowExport] = useState()
  const [showHelp, setShowHelp] = useState(false)
  const [showPolicy, setShowPolicy] = useState(false)
  const [showFirstResetPassword, setShowFirstResetPassword] = useState(false)
  const [openContactDialog, setOpenContactDialog] = useState(false)

  const [showLicense, setShowLicense] = useState(false)
  const [policyId, setPolicyId] = useState()
  const [policyVersion, setPolicyVersion] = useState()
  const [policyType, setPolicyType] = useState()

  const [openContactSend, setOpenContactSend] = useState(false)
  const [openContactFailed, setOpenContactFailed] = useState(false)

  useEffect(() => {
    if (isLoading) return

    //    console.log(JSON.stringify(user))
  }, [isLoading])

  const getUser = async (force) => {
    if (!force && state.user) {
      return
    }

    APIManager.userInfo().then((res) => {
      console.log("[RootView]", "user info", res)
      setUser(res)
      // const app = initializeApp(firebaseConfig)
      // const analytics = getAnalytics(app)
      const type = res.metadata?.TENANT_FIELD?.policy_type ?? "gov"
      setPolicyId(res.policy?.id)
      setPolicyVersion(res.policy?.version)
      setPolicyType(res.policy?.type)

      //setUserId(analytics, res.user_id)
    })
  }

  useEffect(() => {
    APIManager.accessTokenFunc = getAccessTokenSilently

    getUser()

    APIManager.getNotificationMessage().then((res) => {
      console.log("[NotificationMessage]", res)
      setNotificationMessage(res?.message)
    })

    APIManager.getEnv().then((res) => {
      setServerEnv(res)
    })
  }, [])

  const showOptionFaq = useCallback(() => {
    window.open(`/jikoraat_faq_v${state.user?.env?.FAQ_VERSION ?? "0"}.pdf`)
  }, [state.user?.env])
  const showOptionManual = useCallback(() => {
    window.open(
      `/jikoraat_manual_v${state.user?.env?.MANUAL_VERSION ?? "0"}.pdf`
    )
  }, [state.user?.env])
  const showOptionReleaseNote = useCallback(() => {
    window.open(
      `/jikoraat_updates_v${state.user?.env?.UPDATE_VERSION ?? "0"}.pdf`
    )
  }, [state.user?.env])

  const handleCloseContactSend = useCallback(() => {
    setOpenContactSend(false)
  }, [])

  const handleCloseContactFailed = useCallback(() => {
    setOpenContactFailed(false)
  }, [])

  return (
    <RootDataContext.Provider value={useRootData}>
      <Box sx={{ ...styles.root, ...props.sx }}>
        <Box
          style={{
            margin: "8px 0",
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            backgroundColor: "#f3f3f3",
            alignItems: "center",
          }}
        >
          {/* 上部表示切り替えボタン */}
          <Button
            sx={{
              ...styles.viewSwitchButton,
              ...(state.viewType === ViewType.map ? styles.activeButton : null),
            }}
            onClick={() => setViewType(ViewType.map)}
          >
            地図
          </Button>
          <Button
            sx={{
              ...styles.viewSwitchButton,
              ...(state.viewType === ViewType.list
                ? styles.activeButton
                : null),
            }}
            onClick={() => setViewType(ViewType.list)}
          >
            リスト
          </Button>
          <Box sx={{ m: 0.5 }} />
          <OptionView
            onLogout={() => logout({ returnTo: window.location.origin })}
            onExport={() => setShowExport(true)}
            onContact={() => setOpenContactDialog(true)}
            onHelp={() => setShowHelp(true)}
            onLicense={() => setShowLicense(true)}
            onFaq={() => showOptionFaq()}
            onManual={() => showOptionManual()}
            onReleaseNote={() => showOptionReleaseNote()}
          />
          <Box sx={{ m: 0.5 }} />
          <RootPredictionModeView sx={styles.predictionMode} />
          <Box sx={{ m: 0.5 }} />
          <RootPlaceSearchView sx={styles.placeSearch} />
          {state.viewType === ViewType.list && (
            <RootCommentSearchView style={styles.commentSearch} />
          )}
          <Box style={{ flexGrow: 1 }} />
          <img src={LogoImage} style={{ height: "43px" }} />
        </Box>
        <Box
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={styles.content}>
            {state.viewType === ViewType.map && <MapContents />}
            {state.viewType === ViewType.list && <ListContents />}
          </Box>
        </Box>
        {/* お知らせ */}
        {notificationMessage && (
          <NotificationView
            message={notificationMessage}
            onLoopFinish={(e, i, j) => {
              setNotificationMessage(null)
            }}
          />
        )}
      </Box>
      {state.loadingCount > 0 && (
        <Box
          sx={{
            backgroundColor: "rgba(194,193,193,0.12)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2000,
          }}
        >
          <CircularProgress sx={{ color: "#476731" }} />
        </Box>
      )}
      {showExport && (
        <ExportView
          onClose={() => {
            setShowExport(null)
          }}
        />
      )}
      <StateDialog />
      {showPolicy && (
        <TermsOfServiceView
          onClose={(message) => {
            // TODO: エラー時にメッセージ
            setShowPolicy(false)
          }}
        />
      )}
      {showHelp && <RootHelpView onClose={() => setShowHelp(false)} />}
      <Modal open={showFirstResetPassword}>
        <RootResetPasswordView
          first={true}
          force={true}
          onChanged={(user) => {
            setUser(user)
          }}
          onClose={() => {
            setShowFirstResetPassword(false)
          }}
        />
      </Modal>
      <MemoView />
      <Dialog
        open={state.commentPreviewImage}
        fullWidth
        onClose={() => setCommentPreviewImage(null)}
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "90%",
            maxHeight: "90%",
          }}
        >
          <img
            src={state.commentPreviewImage}
            style={{ maxWidth: "80%", maxHeight: "80%" }}
          />
          <IconButton
            style={{ position: "absolute", top: "8px", right: "8px" }}
            onClick={() => {
              const tempLink = document.createElement("a")
              tempLink.href = state.commentPreviewImage
              tempLink.setAttribute("download", "image.png")
              tempLink.click()
            }}
          >
            <DownloadIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
      <ContactDialog
        open={openContactDialog}
        onClose={() => setOpenContactDialog(false)}
        onSend={() => setOpenContactSend(true)}
        onSendFailure={() => setOpenContactFailed(true)}
      />
      {showLicense && (
        <TermsOfServiceView
          policyId={policyId}
          policyVersion={policyVersion}
          policyType={policyType}
          onClose={() => setShowLicense(false)}
        />
      )}
      <Snackbar
        open={openContactSend}
        autoHideDuration={5000}
        onClose={handleCloseContactSend}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseContactSend}
          severity="success"
          sx={{ width: "100%" }}
        >
          お問い合わせを送信しました
        </Alert>
      </Snackbar>
      <Snackbar
        open={openContactFailed}
        autoHideDuration={5000}
        onClose={handleCloseContactFailed}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseContactFailed}
          severity="error"
          sx={{ width: "100%" }}
        >
          お問い合わせの送信に失敗しました
        </Alert>
      </Snackbar>
    </RootDataContext.Provider>
  )
})

RootView.propTypes = {
  sx: PropTypes.object,
  onLogout: PropTypes.func,
}

export default RootView
