import React, { useContext, useState } from "react"
import LeftPanelView from "./leftPanel"
import { Box } from "@mui/material"
import HeaderView from "./header"
import MapView, {LAYERS_CONFIG} from "./map"
import RightPanelView from "./rightPanel"
import PropTypes from "prop-types"
import { RootDataContext } from "../index"

const styles = {
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flexGrow: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  rightPanel: {
    width: "350px",
    height: "100%",
    margin: "0 8px",
  },
}

const RootMapContentsView = (props) => {
  const { state } = useContext(RootDataContext)
  const [map, setMap] = useState()
  const [rightPanelHidden, setRightPanelHidden] = useState(false)
  const onMapLoad = (m) => {
    setMap(m)

    m.on("zoom", () => {
      let panelHidden = LAYERS_CONFIG.find(conf => {
        if (conf.zoom.minzoom && parseInt(m.getZoom()) > conf.zoom.minzoom) { return conf.rightSidePanelHidden }
        if (conf.zoom.maxzoom && parseInt(m.getZoom()) <= conf.zoom.maxzoom) { return conf.rightSidePanelHidden }
        return false
      })
      setRightPanelHidden(panelHidden)
    })

  }

  return (
    <Box sx={{ ...styles.root, ...props.styles }}>
      <HeaderView />
      <Box sx={styles.main}>
        <LeftPanelView />
        <MapView onLoad={onMapLoad} />
        {state.visibleRightPane && <RightPanelView sx={styles.rightPanel} />}
      </Box>
    </Box>
  )
}

RootMapContentsView.propTypes = {
  styles: PropTypes.object,
  onComment: PropTypes.func,
}

export default RootMapContentsView
