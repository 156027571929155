import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIManager from "../../../manager/api"
import { RootDataContext } from "../index"

const Type = {
  question: "question",
  idea: "idea",
  request: "request",
}

const TypeName = {
  question: "ご質問",
  idea: "ご意見",
  request: "ご要望",
}

const TypeMessage = {
  question:
    "当アプリケーションに関する質問をご記載ください。なお、よくある質問はオプション⇒FAQよりご確認いただけます。",
  idea: "当アプリケーションの改善点等、ご自由にご記載ください。",
  request:
    "当アプリケーションの機能に関わらず、要望に関してご自由にご記載ください。",
}

const messages = {
  ご質問: "question",
  ご意見: "idea",
  ご要望: "request",
}

const RootContactDialogView = (props) => {
  const { state } = useContext(RootDataContext)
  const [type, setType] = useState("ご質問")
  const [email, setEmail] = useState()
  const [body, setBody] = useState()
  const [message, setMessage] = useState()
  const [openConfirm, setOpenConfirm] = useState(false)

  const onCloseConfirm = () => {
    setOpenConfirm(false)
  }

  useEffect(() => {
    if (body || !state.user) {
      return
    }
    let items = []

    if (state.user.group_name) {
      items.push(`所属【${state.user.group_name}】`)
    } else {
      items.push(`所属【】`)
    }
    if (state.user.user_name) {
      items.push(`名前【${state.user.user_name}】`)
    } else {
      items.push(`名前【】`)
    }

    setBody(items.join("\n") + "\n")
  }, [body, state.user?.group_name, state.user?.tenant_name])

  const onChangeBody = (e) => {
    setBody(e.target.value)
  }

  const onChangeType = (e) => {
    setType(e.target.value)
    let msg = TypeMessage[e.target.value]
    setMessage(msg)
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const onConfirm = () => {
    if (
      !email ||
      !String(email).match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
    ) {
      alert("メールアドレスが入力されていないか正しくありません")
      return
    }

    setOpenConfirm(true)
  }

  const onSend = () => {
    setOpenConfirm(false)

    let typeName = TypeName[type]
    APIManager.sendContact(typeName, email, body)
      .then((res) => {
        setBody(null)
        setEmail(null)
        setType(null)

        props.onSend && props.onSend(res)
        props.onClose && props.onClose()
      })
      .catch((e) => {
        setBody(null)
        setEmail(null)
        setType(null)
        props.onSendFailure && props.onSendFailure(e)
        setBody(null)
        props.onClose && props.onClose()
      })
  }

  return (
    <>
      <Dialog open={props.open} fullWidth={true}>
        <DialogTitle>お問い合わせ</DialogTitle>
        <DialogContent style={{ width: "100%" }}>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
              <Select
                size="small"
                label="お問い合わせ"
                value={type}
                style={{ width: "180px" }}
                onChange={onChangeType}
              >
                {Object.keys(Type).map((k) => {
                  let name = TypeName[k]
                  return (
                    <MenuItem value={k} key={k}>
                      {name}
                    </MenuItem>
                  )
                })}
              </Select>
              <Box>
                <Typography style={{ fontSize: "12px" }}>{message}</Typography>
              </Box>
            </Box>
            <Box>
              <Typography>メールアドレス</Typography>
              <TextField
                type="email"
                style={{ width: "100%" }}
                size="small"
                inputProps={{ inputMode: "email" }}
                value={email}
                onChange={onChangeEmail}
              />
            </Box>
            <Box>
              <Typography>お問い合わせ内容</Typography>
              <TextareaAutosize
                value={body}
                minRows={10}
                onChange={onChangeBody}
                style={{ width: "100%" }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setBody(null)
              setEmail(null)
              setType(null)
              props.onClose && props.onClose()
            }}
          >
            キャンセル
          </Button>
          <Button onClick={onConfirm}>送信</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={onCloseConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">送信確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            お問い合わせを送信します。 よろしいですか
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseConfirm}>キャンセル</Button>
          <Button onClick={onSend} autoFocus>
            送信する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

RootContactDialogView.propTypes = {
  open: PropTypes.bool,
  onSend: PropTypes.func,
  onSendFailure: PropTypes.func,
  onClose: PropTypes.func,
}

export default RootContactDialogView
