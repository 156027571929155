import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { MessageOutlined as MessageIcon } from "@mui/icons-material"
import Marquee from "react-fast-marquee"

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center",
    margin: "8px 0",
  },
  message: {
    height: "100%",
    flexGrow: 1,
    border: "1px solid #000",
    alignItems: "center",
    display: "flex",
  },
}

const RootNotificationView = (props) => {
  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Box
        sx={{
          width: "200px",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "0 16px",
          backgroundColor: "#8d8d8d",
          color: "white",
          alignItems: "center",
        }}
      >
        <MessageIcon />
        <Typography>お知らせ</Typography>
      </Box>
      <Marquee
        sx={styles.message}
        delay={1}
        speed={40}
        loop={3}
        onFinish={props.onLoopFinish}
      >
        <Typography>{props.message}</Typography>
      </Marquee>
    </Box>
  )
}

RootNotificationView.propTypes = {
  sx: PropTypes.object,
  message: PropTypes.string,
  onLoopFinish: PropTypes.func,
}

export default RootNotificationView
