import PropTypes from "prop-types"
import { Box } from "@mui/material"
import AggregateCardView from "./aggregateCard"
import React, { useContext } from "react"
import PredictKousatenCard from "./predictCard/kousaten"
import PredictDouroCard from "./predictCard/douro"
import PredictAverageCard from "./predictCard/average"
import MapDetailInfoCard from "./mapDetailInfoCard";
import RiskHistCard from "./riskHistCard"
import { RootDataContext } from "../../index"

const styles = {
  card: {
    margin: "5px 0",
  },
}

const RootRightPanelView = (props) => {
  const { state } = useContext(RootDataContext)

  return (
    <Box
      sx={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        ...props.sx,
      }}
    >
      {/* 右ペイン */}
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ flexGrow: 1 }} />
      </Box>
        {(state.viewData.includes("道路事故評価") ||
            state.viewData.includes("交差点事故評価") || state.viewData.includes("事故実績")) && <AggregateCardView />}
      <Box>
          {state.mapDetailInfo && ["douro", "kosaten"].includes(state.mapDetailInfo.type) && <RiskHistCard />}
          {state.mapDetailInfo && <MapDetailInfoCard sx={styles.card} />}
      </Box>
    </Box>
  )
}

RootRightPanelView.propTypes = {
  sx: PropTypes.object,
}

export default RootRightPanelView
