import React, { useEffect, useState } from "react"
import "./App.css"
import RootView from "./views/root"
import LoginView from "./views/login"
import { createTheme } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import { red } from "@mui/material/colors"
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react"
import { Component } from "ag-grid-community"
import { useNavigate } from "react-router"
import PropTypes from "prop-types"
import RootRangeTypeSelectorView from "./views/root/map/header/rangeTypeSelector"
import ChangePasswordView from "./views/change_password"
import TermsOfServiceView from "./views/terms_of_service"
import UseAuthData from "./data/auth"
import AuthHook from "./views/authHook";
import {LicenseManager} from "ag-grid-enterprise";

if (process.env.REACT_APP_AGGRID_LICENSE_KEY) {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSE_KEY)
}

export const AuthDataContext = React.createContext()

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c6960",
    },
  },
})

const ProtectedRoute = (props) => {
  const Component = withAuthenticationRequired(props.component, {})
  return <Component />
}
ProtectedRoute.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
}

const Auth0RouteProvider = (props) => {
  const navigate = useNavigate()

  const onRedirectCallback = (appState) => {
    let path = appState?.returnTo || window.location.pathname
    console.log('Redirect callback', path)
    //navigate(appState?.returnTo || window.location.pathname)
    navigate(path)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {props.children}
    </Auth0Provider>
  )
}
Auth0RouteProvider.propTypes = {
  children: PropTypes.any,
}

// eslint-disable-next-line react/display-name
const App = React.memo(() => {
  const useAuthData = UseAuthData()

  const [login, setLogin] = useState(sessionStorage.getItem("user_uid"))

  console.log('App render with');

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Auth0RouteProvider>
          <Routes>
            <Route path="/" element={<ProtectedRoute component={RootView} />} />
            <Route
              path="/change_passowrd"
              element={<ProtectedRoute component={ChangePasswordView} />}
            />
            <Route
              path="/lisence"
              element={<ProtectedRoute component={TermsOfServiceView} />}
            />
            <Route
                path="/auth_hook"
                element={< AuthHook />}
            />
          </Routes>
        </Auth0RouteProvider>
      </Router>
    </ThemeProvider>
  )
})

export default App
