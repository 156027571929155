import PropTypes from "prop-types"
import React, { useContext, useMemo, useRef, useState } from "react"
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material"
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Check as CheckIcon,
} from "@mui/icons-material"

const styles = {
  root: {
    textAlign: "center",
    color: "black !important",
  },
  arrowIcon: {
    position: "absolute",
    right: "12px",
    color: "#3c6960",
  },
}

const RootFilterSelectorView = (props) => {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef()
  const keyValues = useMemo(
    () => Object.fromEntries(props.values.map((e) => [e.value, e.label])),
    [props.values]
  )
  const textColor = useMemo(() => {
    if (!keyValues) {
      return "black"
    }
    if (keyValues[props.value]) return "black"
    return "#707070"
  }, [props.value, keyValues])
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Button
        id={`selector_${props.key}`}
        sx={{ position: "relative", width: "100%" }}
        ref={buttonRef}
        onClick={handleOpen}
      >
        <Typography variant="subtitle2" sx={{ color: textColor }}>
          {keyValues[props.value] ?? props.name}
        </Typography>
        {open && <ArrowDropUpIcon fontSize="large" sx={styles.arrowIcon} />}
        {!open && <ArrowDropDownIcon fontSize="large" sx={styles.arrowIcon} />}
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": props.key,
        }}
        PaperProps={{
          style: {
            width: "180px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            props.onChange(null)
            handleClose()
          }}
        >
          <em>選択解除</em>
        </MenuItem>
        {props.values.map((e, i) => {
          return (
            <MenuItem
              key={i}
              onClick={() => {
                props.onChange(e.value)
                handleClose()
              }}
            >
              {e.label}
              {props.value === e.value && (
                <CheckIcon fontSize="small" sx={{ marginLeft: "8px" }} />
              )}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

RootFilterSelectorView.propTypes = {
  sx: PropTypes.object,
  key: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
}

export default RootFilterSelectorView
