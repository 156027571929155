import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Box, Button, Typography } from "@mui/material"
import { RootDataContext } from "../../index"
import APIManager from "../../../../manager/api"
import dayjs from "dayjs"
import { MemoDataContext } from "../index"

const styles = {
  root: {
    borderColor: "#eee",
    borderStyle: "solid",
    borderWidth: "0 1px 0 0",
    padding: "8px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 50px)",
  },
  threadListBox: {
    flexGrow: 1,
  },
  thread: {
    color: "#3d3b3b",
    fontSize: "13px",
    fontFamily: "sans-serif",
    padding: "8px 0 8px 4px",
    textAlign: "left",
    overflowX: "hidden",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e8e8e8",
      fontStyle: "italic",
    },
  },
  titleBox: {
    display: "flex",
    flexDirection: "row",
  },
  newThread: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
    openLabel: {
        fontSize: "12px",
        fontStyle: "normal",
        border: "1px solid #666",
        borderRadius: "4px",
        padding: "2px 8px",
        marginLeft: "8px",
        whiteSpace: "nowrap",
    },
    openLabel_close: {
        backgroundColor: "#999",
        color: "#fff",
    },
    openLabel_open_other: {
        backgroundColor: "white",
        color: "#006c60",
    },
    openLabel_open_owner: {
        backgroundColor: "#006c60",
        color: "white",
    },
}

// eslint-disable-next-line react/display-name
const MemoThreadView = React.memo((props) => {
  const { state } = useContext(RootDataContext)
  const {
    state: memoState,
    setThread,
    setThreadId,
  } = useContext(MemoDataContext)
  const [elems, setElems] = useState(null)

  useEffect(() => {
    if (!state.showMemoData?.type || !state.showMemoData?.uuid) {
      return setElems(null)
    }

    APIManager.getMemoThreads(
      state.showMemoData.type,
      state.showMemoData.uuid
    ).then((rows) => {



      setElems(
        rows.map((row) => {

            let openLabelStyles = styles.openLabel
            if (!row.is_open) {
                openLabelStyles = {...openLabelStyles, ...styles.openLabel_close}
            } else if (row.is_editable) {
                openLabelStyles = {...openLabelStyles, ...styles.openLabel_open_owner}
            } else {
                openLabelStyles = {...openLabelStyles, ...styles.openLabel_open_other}
            }

          return (
            <Box
              key={`${state.showMemoData.type}|${state.showMemoData.uuid}|${row.thraed_id}`}
              sx={styles.thread}
              onClick={() => {
                setThread(row)
              }}
            >
              <Box sx={styles.titleBox}>
                <Box sx={{flexGrow: 1}}>
                  <Typography
                    style={{
                      fontSize: "13px",
                      ...(row.thread_id === memoState.threadId
                        ? { fontWeight: 700, fontStyle: "italic" }
                        : {}),
                    }}
                  >
                    {row.title ??
                      dayjs(row.created_at_jst).format("YYYY年M月D日 H時m分")}
                    ({row.comment_count ?? 0})
                  </Typography>
                </Box>
                <Box>
                  <span
                    style={openLabelStyles}
                  >
                    {row.is_open ? "公開" : "非公開"}
                  </span>
                </Box>
              </Box>

              <Box sx={{ width: "100%" }}>
                <Typography
                  style={{
                    fontSize: "9px",
                    textAlign: "right",
                    color: "#585858",
                  }}
                >
                  <Box>
                    作成 {row.user_name}{" "}
                    {dayjs(row.created_at_jst).format("YYYY年M月D日 H時m分")}
                  </Box>
                  {row.last_edited_at_jst && (
                    <Box>
                      最終更新 {row.last_edited_user_name}{" "}
                      {dayjs(row.last_edited_at_jst).format(
                        "YYYY年M月D日 H時m分"
                      )}
                    </Box>
                  )}
                </Typography>
              </Box>
            </Box>
          )
        })
      )
    })
  }, [state.showMemoData, memoState.update])

  const getElems = useCallback(async () => {}, [
    state.showMemoData,
    memoState.update,
  ])

  const data = useMemo(async () => {
    if (!state.showMemoData?.type || !state.showMemoData?.uuid) {
      return null
    }

    let val = await APIManager.getMemoThreads(
      state.showMemoData.type,
      state.showMemoData.uuid
    )
    console.log("[ThreadList]", "Data updated", val)
    if (val.length === 0) {
      return null
    }
    return val
  }, [state.showMemoData, memoState.update])

  return (
    <Box
      sx={{
        minWidth: "210px",
        backgroundColor: "#eee",
        boxShadow: "-1px 0 5px -5px #414141;",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#006c60",
          boxShadow: "0 1px 8px -2px #414141",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "4px 0",
          marginBottom: "4px",
        }}
      >
        <Box
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "white",
          }}
        >
          スレッド一覧
        </Box>
      </Box>
      <Box sx={styles.root}>
        {!memoState.threadId && (
          <Box
            sx={{
              fontSize: "14px",
              marginLeft: "4px",
              fontWeight: "700",
              fontStyle: "italic",
            }}
          >
            新規スレッド
          </Box>
        )}
        <Box sx={styles.threadListBox}>{elems}</Box>
        <Box sx={styles.newThread}>
          <Button
            style={{
              width: "90%",
              backgroundColor: "#006c60",
            }}
            variant="contained"
            onClick={() => {
              setThread(null)
            }}
          >
            新規スレッド
          </Button>
        </Box>
      </Box>
    </Box>
  )
})

MemoThreadView.propTypes = {}

export default MemoThreadView
