import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { RootDataContext } from "../../../index"
import DataManager from "../../../../../manager/data"
import APIManager from "../../../../../manager/api"
import { LAYERS_CONFIG } from "../index"

const SOURCE_NAME = "prediction_kosaten_yosoku"
const LAYER_NAME = "prediction_kosaten_yosoku_layer"

const LAYER_DEFAULT_PAINT = {
  //"circle-color": "#ff0", //                 marker-fill: ramp([risk], (#1a9641, #a6d96a, #ffffbf, #fdae61, #d7191c), (0.1,0.25,0.5,0.75,1.1), '>', jenks());
  "circle-color": [
    "case",
    ["<", ["get", "risk"], 0.1],
    "#1a9641",
    ["all", [">=", ["get", "risk"], 0.1], ["<", ["get", "risk"], 0.25]],
    "#a6d96a", // allてのはandってこと
    ["all", [">=", ["get", "risk"], 0.25], ["<", ["get", "risk"], 0.5]],
    "#ffffbf",
    ["all", [">=", ["get", "risk"], 0.5], ["<", ["get", "risk"], 0.75]],
    "#fdae61",
    "#d7191c",
  ],
  "circle-radius": ["interpolate", ["linear"], ["zoom"], 10, 2, 15, 4, 18, 7],
  "circle-stroke-color": "#FFF",
  "circle-stroke-width": [
    "interpolate",
    ["linear"],
    ["zoom"],
    10,
    1,
    15,
    2,
    18,
    3,
  ],
  "circle-stroke-opacity": 0.65,
}

const PredictKosatenLayer = (props) => {
  const { state } = useContext(RootDataContext)
  const map = useMemo(() => props.map, [props.map])
  const canvas = useMemo(() => map?.getCanvasContainer(), [map])
  const [tiles, setTiles] = useState()
  const [initialized, setInitialized] = useState(false)
  const initializedRef = useRef()

  useEffect(() => {
    if (!map || !canvas || !state.user || !state.predictionMode) {
      return
    }
    Promise.all(
      LAYERS_CONFIG.map(async (conf) => {
        let query = DataManager.selectQueryKosatenjikoYosoku(
          state.user,
          state.predictionMode,
          state.schoolArea?.name,
          { risk_idx: conf.riskIndex }
        )
        let sourceName = `${SOURCE_NAME}_${conf.name}`
        let layerName = `${LAYER_NAME}_${conf.name}`
        let tiles = await APIManager.getVectorTile(query)
        let source = map.getSource(sourceName)
        if (source) {
          source.setTiles(tiles)
          return
        }
        map.addSource(sourceName, {
          type: "vector",
          tiles,
          ...conf.zoom,
        })
        map.addLayer({
          id: layerName,
          type: "circle",
          source: sourceName,
          "source-layer": "layer0",
          ...conf.zoom,
          layout: {},
          paint: LAYER_DEFAULT_PAINT,
        })
        map
          .on("click", layerName, (e) => {
            props.onClick && props.onClick(e)
          })
          .on("mouseenter", layerName, () => {
            canvas.style.cursor = "pointer"
          })
          .on("mouseleave", layerName, () => {
            canvas.style.cursor = "grab"
          })
      })
    ).then(() => {
      if (!initializedRef.current) {
        initializedRef.current = true
        setInitialized(true)
        props.onLoad && props.onLoad()
      }
    })
  }, [map, canvas, state.user, state.predictionMode, state.schoolArea])

  useEffect(() => {
    if (!map || !initialized) {
      return
    }

    LAYERS_CONFIG.forEach((conf) => {
      map.setLayoutProperty(
        `${LAYER_NAME}_${conf.name}`,
        "visibility",
        state.viewData.includes("交差点事故評価") ? "visible" : "none"
      )
    })
  }, [map, initialized, state.viewData])

  return null
}

export const Order = (map, beforeLayer) => {
  let _before = beforeLayer
  for(let conf of LAYERS_CONFIG) {
    map.moveLayer(`${LAYER_NAME}_${conf.name}`, beforeLayer)
    _before = `${LAYER_NAME}_${conf.name}`
  }
}

export const MAJOR_LAYER_NAME = `${LAYER_NAME}_level5`

PredictKosatenLayer.propTypes = {
  map: PropTypes.any,
  onLoad: PropTypes.func,
  onClick: PropTypes.func,
}

export default PredictKosatenLayer
