import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

const styles = {
  root: {
    position: "relative",
    width: "200px",
    height: "70px",
    backgroundColor: "white",
    boxShadow: "2px 2px 3px rgba(0,0,0,.4)",
    borderRadius: "4px",
  },
  title: {
    position: "relative",
    margin: "6px 0 3px 12px !important",
  },
  bar: {
    width: "calc(100% - 32px)",
    height: "9px",
    marginLeft: "16px",
    background:
      "linear-gradient(.25turn, #1a9641, #a6d96a, #ffffbf, #fdae61, #d7191c)",
    position: "absolute",
    borderRadius: "3px",
  },
  min_label: {
    position: "absolute",
    left: "5px",
    top: "40px",
  },
  max_label: {
    position: "absolute",
    right: "8px",
    top: "40px",
  },
}

const RootMapKousatenLegends = (props) => {
  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Typography variant="subtitle2" sx={styles.title}>
        交差点事故リスク
      </Typography>
      <Box sx={styles.bar} />
      <Typography variant="caption" sx={styles.min_label}>
        0.0
      </Typography>
      <Typography variant="caption" sx={styles.max_label}>
        1.0
      </Typography>
    </Box>
  )
}

RootMapKousatenLegends.propTypes = {
  sx: PropTypes.object,
}

export default RootMapKousatenLegends
