import initialState from "./state"
import reducer, { ActionType } from "./reducer"
import { useReducer } from "react"

export const ViewType = {
  list: "list",
  map: "map",
}

let loadingTimeoutTimer = null

const UseRootViewData = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const setUserUid = (uid) => {
    dispatch({ type: ActionType.setUserUId, value: uid })
  }

  const setUser = (value) => {
    dispatch({ type: ActionType.setUser, value: value })
  }

  const setTenant = (value) => {
    dispatch({type: ActionType.setTenant, value: value})
  }

  const setViewType = (type) => {
    dispatch({ type: ActionType.setViewType, value: type })
  }

  const setTimeslotFilter = (value) => {
    dispatch({ type: ActionType.setTimeslotFilter, value: value })
  }

  const setAgeFilter = (value) => {
    dispatch({ type: ActionType.setAgeFilter, value: value })
  }

  const setTypeFilter = (value) => {
    dispatch({ type: ActionType.setTypeFilter, value: value })
  }

  const setInjuryFilter = (value) => {
    dispatch({ type: ActionType.setInjuryFilter, value })
  }

  const setWeatherFilter = (value) => {
    dispatch({ type: ActionType.setWeatherFilter, value })
  }

  const setViewDataVisible = (value, visible = true) => {
    let data = Array.from(state.viewData)
    if (data.includes(value) && !visible) {
      data = data.filter((e) => e !== value)
    } else if (!data.includes(value) && visible) {
      data.push(value)
    } else {
      return
    }
    dispatch({ type: ActionType.setViewData, value: data })

    dispatch({
      type: ActionType.setVisibleRightPane,
      value:
        data.includes("事故実績") ||
        data.includes("道路事故評価") ||
        data.includes("交差点事故評価"),
    })
  }

  const toggleViewData = (value) => {
    let data = Array.from(state.viewData)
    if (data.includes(value)) {
      data = data.filter((e) => e !== value)
    } else {
      data.push(value)
    }
    dispatch({ type: ActionType.setViewData, value: data })
    dispatch({type: ActionType.setMapDetailInfo, value: null})
    dispatch({
      type: ActionType.setVisibleRightPane,
      value:
        data.includes("事故実績") ||
        data.includes("道路事故評価") ||
        data.includes("交差点事故評価"),
    })
  }

  const setPredictionMode = (value) => {
    dispatch({ type: ActionType.setPredictionMode, value: value })
  }

  const setMapViewBounds = (value) => {
    dispatch({ type: ActionType.setMapViewBounds, value: value })
  }

  const setMapCenter = (value) => {
    dispatch({ type: ActionType.setMapCenter, value: value })
  }

  const setRangeType = (value) => {
    dispatch({ type: ActionType.setRangeType, value: value })
  }

  const setPinLocation = (value) => {
    dispatch({ type: ActionType.setViewType, value: ViewType.map })

    setTimeout(() => {
      dispatch({ type: ActionType.setPinLocation, value: value })
    }, 500)
  }

  const addLoadingCount = (timeout) => {
    dispatch({
      type: ActionType.setLoadingCount,
      value: state.loadingCount + 1,
    })

    if (timeout) {
      loadingTimeoutTimer = setTimeout(() => {
        dispatch({ type: ActionType.setLoadingCount, value: 0 })
      }, timeout)
    }
  }

  const subLoadingCount = () => {
    dispatch({
      type: ActionType.setLoadingCount,
      value: state.loadingCount - 1,
    })
  }

  const clearLoadingCount = () => {
    dispatch({ type: ActionType.setLoadingCount, value: 0 })
  }

  const appendClickedActionData = (data) => {
    if (!state.clickActionData) {
      dispatch({ type: ActionType.setClickActionData, value: [data] })
    } else {
      dispatch({
        type: ActionType.setClickActionData,
        value: state.clickActionData.push(data),
      })
    }
  }

  const clearClickedActionData = () => {
    dispatch({ type: ActionType.setClickedActionData, value: null })
  }

  const setCommentEditMode = (value) => {
    dispatch({ type: ActionType.setCommentEditMode, value: value })
    dispatch({ type: ActionType.setCommentEditData, value: null })
  }

  const addCommentEditData = (value) => {
    let v = state.commentEditData ?? []
    v.push(value)

    dispatch({ type: ActionType.setCommentEditData, value: v })
  }

  const replaceCommentEditData = (type, dataIndex, data) => {
    let c = Array.from(state.commentEditData)
    for (var i = 0; i < c.length; i++) {
      if (c[i].type === type && c[i].dataIndex === dataIndex) {
        c[i] = { ...c[i], data }
        break
      }
    }
    dispatch({ type: ActionType.setCommentEditData, value: c })
  }

  const setCommentEditData = (value) => {
    dispatch({ type: ActionType.setCommentEditData, value: value })
  }

  const openStateDialog = ({ title, content, actions, onClose }) => {
    dispatch({ type: ActionType.setStateDialogTitle, value: title })
    dispatch({ type: ActionType.setStateDialogContent, value: content })
    dispatch({ type: ActionType.setStateDialogActions, value: actions })
    dispatch({ type: ActionType.setStateDialogOnClose, value: onClose })
    setTimeout(() => {
      dispatch({ type: ActionType.setStateDialogOpen, value: true })
    }, 10)
  }

  const closeStateDialog = () => {
    dispatch({ type: ActionType.setStateDialogOpen, value: false })
  }

  const setSchoolArea = (value) => {
    dispatch({ type: ActionType.setSchoolArea, value: value })
  }

  const setShowMemoData = (value) => {
    dispatch({ type: ActionType.setShowMemoData, value: value })
  }

  const setShowMemoThreadData = (value) => {
    dispatch({type: ActionType.setShowMemoThreadData, value: value})
  }

  const setCommentPreviewImage = (value) => {
    dispatch({ type: ActionType.setCommentPreviewImage, value: value })
  }

  const setMapDetailInfo = (value) => {
    dispatch({ type: ActionType.setViewType, value: ViewType.map })
    setTimeout(() => {
      dispatch({type: ActionType.setMapDetailInfo, value: value})
    }, 100)
  }

  const setServerEnv = (value) => {
    dispatch({type: ActionType.setServerEnv, value: value})
  }

  const setSearchComment = (value) => {
    dispatch({type: ActionType.setSearchComment, value: value})
  }

  return {
    state,
    setUser,
    setTenant,
    setUserUid,
    setViewType,
    setTimeslotFilter,
    setAgeFilter,
    setTypeFilter,
    setInjuryFilter,
    setWeatherFilter,
    setViewDataVisible,
    toggleViewData,
    setPredictionMode,
    setMapViewBounds,
    setMapCenter,
    setRangeType,
    setPinLocation,
    appendClickedActionData,
    clearClickedActionData,
    addLoadingCount,
    subLoadingCount,
    clearLoadingCount,
    setCommentEditMode,
    addCommentEditData,
    setCommentEditData,
    replaceCommentEditData,
    openStateDialog,
    closeStateDialog,
    setSchoolArea,
    setShowMemoData,
    setShowMemoThreadData,
    setCommentPreviewImage,
    setMapDetailInfo,
    setServerEnv,
    setSearchComment,
  }
}

export default UseRootViewData
