import { useReducer } from "react"
import reducer, { ActionType } from "./reducer"
import initialState from "./state"

const UseAuthData = () => {
  const [authState, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const setUser = (user) => {
    dispatch({ type: ActionType.setUser, value: user })
  }

  const setAccessToken = (accessToken) => {
    dispatch({ type: ActionType.setAccessToken, value: accessToken })
  }

  return {
    authState,
    setUser,
    setAccessToken,
  }
}

export default UseAuthData
