import React from "react"
import {useContext} from "react";
import {RootDataContext} from "../../../index";
import RiskHistGraphKosatenView from "./kosaten"
import RiskHistGraphDouroView from "./douro"
import {Box, Typography} from "@mui/material";

const styles = {
    root: {
        backgroundColor: "#f3f3f3",
        padding: "8px",
        marginBottom: "5px",
        color: "#000",
    },
    pieBox: {
        margin: "8px",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
    },
    chart: {
        width: "calc(50% - 4px)", // なぜかコレをしないとChartJSが100%で出力される

    },
}
const RootRiskHistCardView = (props) => {

    const {state } = useContext(RootDataContext)

    return (
        <Box sx={styles.root}>
            <Typography style={{marginLeft: "4px", marginBottom: "4px"}}>{state.mapDetailInfo.type === "douro" ? "道路" : "交差点"}リスク履歴</Typography>
            {state.mapDetailInfo.type === "douro" && <RiskHistGraphDouroView />}
            {state.mapDetailInfo.type === "kosaten" && <RiskHistGraphKosatenView />}
        </Box>
    )
}

export default RootRiskHistCardView
