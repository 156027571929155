import PropTypes from "prop-types"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { RootDataContext } from "../../../index"
import DataManager from "../../../../../manager/data"
import APIManager from "../../../../../manager/api"
import { LAYERS_CONFIG } from "../index"

const SOURCE_NAME = "prediction_douro_yosoku"
const LAYER_NAME = "prediction_douro_yosoku_layer"

const LAYER_ZOOM = {
  H: { minzoom: 10, maxzoom: 13 },
  HL: { minzoom: 13, maxzoom: 15 },
  L: { minzoom: 15, maxzoom: 22 },
}

const LAYER_DEFAULT_PAINT = {
  "line-color": [
    "case",
    ["<", ["get", "risk"], 0.1],
    "#1a9641",
    ["all", [">=", ["get", "risk"], 0.1], ["<", ["get", "risk"], 0.25]],
    "#a6d96a", // allてのはandってこと
    ["all", [">=", ["get", "risk"], 0.25], ["<", ["get", "risk"], 0.5]],
    "#ffffbf",
    ["all", [">=", ["get", "risk"], 0.5], ["<", ["get", "risk"], 0.75]],
    "#fdae61",
    "#d7191c",
  ],
  "line-width": ["interpolate", ["linear"], ["zoom"], 10, 1, 15, 3.0, 18, 4.0],
}

const PredictDouroLayer = (props) => {
  const { state } = useContext(RootDataContext)
  const map = useMemo(() => props.map, [props.map])
  const canvas = useMemo(() => map?.getCanvasContainer(), [map])
  const [tiles, setTiles] = useState()
  const [initialized, setInitialized] = useState(false)
  const initializedRef = useRef(false)

  useEffect(() => {
    if (!map || !canvas || !state.user || !state.predictionMode) {
      return
    }

    Promise.all(
      LAYERS_CONFIG.map(async (conf) => {
        let query = DataManager.selectQueryDourojikoyosoku(
          state.user,
          state.predictionMode,
          state.schoolArea?.name,
          { risk_idx: conf.riskIndex }
        )
        let sourceName = `${SOURCE_NAME}_${conf.name}`
        let layerName = `${LAYER_NAME}_${conf.name}`
        let tiles = await APIManager.getVectorTile(query)
        let source = map.getSource(sourceName)
        if (source) {
          source.setTiles(tiles)
          return
        }
        map.addSource(sourceName, {
          type: "vector",
          tiles,
          ...conf.zoom,
        })
        map.addLayer({
          id: layerName,
          type: "line",
          source: sourceName,
          "source-layer": "layer0",
          ...conf.zoom,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: LAYER_DEFAULT_PAINT,
        })
        map
          .on("click", layerName, (e) => {
            props.onClick && props.onClick(e)
          })
          .on("mouseenter", layerName, () => {
            canvas.style.cursor = "pointer"
          })
          .on("mouseleave", layerName, () => {
            canvas.style.cursor = "grab"
          })
      })
    ).then(() => {
      if (!initializedRef.current) {
        initializedRef.current = true
        setInitialized(true)
        props.onLoad && props.onLoad()
      }
    })
  }, [map, state.user, state.predictionMode, state.schoolArea])

  useEffect(() => {
    if (!map || !initialized) {
      return
    }

    LAYERS_CONFIG.forEach((conf) => {
      map.setLayoutProperty(
        `${LAYER_NAME}_${conf.name}`,
        "visibility",
        state.viewData.includes("道路事故評価") ? "visible" : "none"
      )
    })
  }, [map, initialized, state.viewData])

  return null
}

export const Order = (map, beforeLayer) => {
  let _before = beforeLayer
  for(let conf of LAYERS_CONFIG.reverse()) {
    map.moveLayer(`${LAYER_NAME}_${conf.name}`, beforeLayer)
    _before = `${LAYER_NAME}_${conf.name}`
  }
}

export const MAJOR_LAYER_NAME = `${LAYER_NAME}_level5`

PredictDouroLayer.propTypes = {
  map: PropTypes.any,
  onLoad: PropTypes.func,
  onClick: PropTypes.func,
}

export default PredictDouroLayer
