import React, { useContext, useEffect, useState } from "react"
import { RootDataContext } from "../index"
import { Box, MenuItem, Select } from "@mui/material"
import { predictionModeValues } from "../../../manager/data"

const RootPredictionModeView = () => {
  const { state, setPredictionMode } = useContext(RootDataContext)
  const [value, setValue] = useState("global")
  const [label, setLabel] = useState()

  useEffect(() => {
    let val = state.predictionMode ?? "global"
    let item = predictionModeValues.filter((i) => i.value === val)[0]
    console.log("[PredictionModeView]", "initial item", item, item.value)
    setValue(item.value)
  }, [])

  const onChange = (e) => {
    setValue(e.target.value)
      setPredictionMode(e.target.value)
  }

  return (
    <Box>
      <Select
        size="small"
        value={value}
        onChange={onChange}
        style={{ fontSize: "12px", height: "34px", width: "140px" }}
      >
        {predictionModeValues.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value}>
              {item.label}モデル
            </MenuItem>
          )
        })}
      </Select>
    </Box>
  )
}

export default RootPredictionModeView
