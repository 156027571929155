import dayjs from "dayjs"
import state from "./state"

const initialState = { ...state }

export const ActionType = {
  SetThread: "SET_THREAD",
  SetThreadId: "SET_THREAD_ID",
  SetThreadTitle: "SET_THREAD_TITLE",
  UpdateLastUpdated: "UPDATE_LAST_UPDATED",
  ClearData: "CLEAR_DATA",
}

const MemoDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.SetThread:
      console.log("reducer", "update", action)
        if (action.value) {
          return {
            ...state,
            thread: { ...action.value },
            threadId: action.value?.thread_id,
            threadTitle: action.value?.title,
            update: dayjs().unix(),
          }
        } else {
          return {
            ...state,
            thread: null,
            threadId: null,
            threadTitle: null,
            update: dayjs().unix(),
          }
        }
    case ActionType.SetThreadId:
      return { ...state, threadId: action.value, update: dayjs().unix() }
    case ActionType.SetThreadTitle:
      return { ...state, threadTitle: action.value, update: dayjs().unix() }
    case ActionType.UpdateLastUpdated:
      return { ...state, update: dayjs().unix() }
    case ActionType.ClearData:
      return { ...initialState }
    default:
      break
  }
  return state
}

export default MemoDataReducer
