export const ListViewType = {
  douro: "douro",
  kousaten: "kousaten",
}

const ListViewDataState = {
  viewType: ListViewType.douro,
}

export default ListViewDataState
