import React, { useCallback, useContext, useEffect, useState } from "react"
import { Box, TextField } from "@mui/material"
import PropTypes from "prop-types"
import { RootDataContext } from "../index"
import { ViewType } from "../data"

const styles = {
  root: {},
  textField: {
    width: "100%",
  },
}

let timer

const RootCommentSearchView = (props) => {
  const { state, setSearchComment } = useContext(RootDataContext)
  const [value, setValue] = useState()

  useEffect(() => {
    setValue(null)
      setSearchComment(null)
  }, [state.viewType])

  const onChange = useCallback(
    (e) => {
      setValue(e.target.value)

      clearTimeout(timer)

      timer = setTimeout(() => {
        setSearchComment(e.target.value)
      }, 1000)
    },
    [state.viewType]
  )

  return (
    <Box sx={{ ...styles.root, ...props.style }}>
      <TextField
        style={styles.textField}
        size="small"
        placeholder="メモ検索"
        value={value}
        onChange={onChange}
      />
    </Box>
  )
}

RootCommentSearchView.propTypes = {
  style: PropTypes.object,
}

export default RootCommentSearchView
