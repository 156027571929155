import React from "react"
import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"

const ChangePasswordView = (props) => {
  return (
    <Box sx={props.sx}>
      <Typography>Change password!</Typography>
    </Box>
  )
}

ChangePasswordView.propTypes = {
  sx: PropTypes.object,
}

export default ChangePasswordView
