import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import RootView from "./root"
import { useNavigate } from "react-router"
import { Box, Typography } from "@mui/material"
import axios from "axios"
import TermsOfServiceView from "./terms_of_service"
import { Oval } from "react-loader-spinner"
import dayjs from "dayjs"

const Status = {
  Loading: "LOADING",
  GetInfo: "GET_INFO",
  ShowAgree: "SHOW_AGREE",
  UpdateInfo: "UPDATE_INFO",
  Error: "ERROR",
}

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    flexDirection: "column",
    gap: "8px",
    backgroundColor: "#e1dfe8",
  },
  label: {
    color: "#434343",
    width: "100vw",
    textAlign: "center",
  },
}

// eslint-disable-next-line react/display-name
const AuthHook = React.memo((props) => {
  const [searchParams] = useSearchParams()
  const [userInfo, setUserInfo] = useState()
  const [showTermsOfService, setShowTermsOfService] = useState()
  const [status, setStatus] = useState(Status.Loading)
  const state = useMemo(() => searchParams?.get("state"), [searchParams])
  const [policyId, setPolicyId] = useState()
  const [policyVersion, setPolicyVersion] = useState()
  const userState = useMemo(
    () => searchParams?.get("user_state"),
    [searchParams]
  )
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    if (!userState) {
      return
    }

    console.log(useState, state)

    setStatus(Status.GetInfo)
    axios
      .get(`${process.env.REACT_APP_AUTH_API_BASEURL}/jwt_info`, {
        headers: {
          AuthorizationToken: `Bearer ${userState}`,
        },
      })
      .then((res) => {
        if (
          !res.data.rows ||
          res.data.rows.length === 0 ||
          !res.data.rows[0].payload
        ) {
          console.log(res.data)
          setErrorMessage("ログイン情報が正しく取得できませんでした")
          setStatus(Status.Error)
          return
        }
        setUserInfo({
          ...res.data.rows[0].payload,
          policy: res.data.rows[0].policy,
        })
      })
  }, [userState])

  useEffect(() => {
    if (!userInfo) {
      return
    }

    // 利用規約チェック
    if (
      parseInt(userInfo.policy?.version ?? 0) >
      parseInt(userInfo.policy?.agreed ?? 0)
    ) {
      if (
        !process.env.REACT_APP_ENV_NAME ||
        process.env.REACT_APP_ENV_NAME === "dev"
      ) {
        return onAgree()
      } else {
        setStatus(Status.ShowAgree)
      }
    } else {
      onAgree()
    }
  }, [userInfo])

  const onAgree = useCallback(() => {
    axios
      .post(
        `${process.env.REACT_APP_AUTH_API_BASEURL}/jwt_info`,
        {
          APP_FIELD: {
            ...userInfo.APP_FIELD,
            policy_agreed: parseInt(userInfo.policy?.version ?? 0),
            updates: dayjs().unix(),
          },
          state,
        },
        {
          headers: {
            AuthorizationToken: `Bearer ${userState}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data)
        if (
          !res.data.rows ||
          res.data.rows.length === 0 ||
          !res.data.rows[0].token
        ) {
          console.log(res.data)
          setErrorMessage("ユーザの更新情報を取得できませんでした")
          setStatus(Status.Error)
          return
        }
        send_continue(res.data.rows[0].token)
      })
      .catch((e) => {
        console.log(e)
        setErrorMessage(`ユーザ情報の更新に失敗しましした ${e.message}`)
        setStatus(Status.Error)
        return
      })
  }, [userInfo, userState])

  const send_continue = useCallback(
    (newUserState) => {
      setStatus(Status.UpdateInfo)

      console.log(newUserState)

      const postUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue?state=${state}`
      console.log(postUrl)
      const form = document.createElement("form")
      form.action = postUrl
      form.method = "POST"
      document.body.append(form)
      form.addEventListener("formdata", (e) => {
        const fd = e.formData
        fd.set("user_state", newUserState ?? userState)
      })
      console.log(form)
      debugger
      form.submit()
    },
    [userState, state]
  )

  return (
    <Box>
      {status === Status.ShowAgree && (
        <TermsOfServiceView
          policyId={userInfo?.policy?.id}
          policyType={userInfo?.policy?.type}
          policyVersion={userInfo?.policy?.version}
          onAgree={onAgree}
        />
      )}
      {status !== Status.ShowAgree && (
        <Box sx={styles.root}>
          <Oval
            height={120}
            width={120}
            color="#616bcc"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#7a81c9"
            strokeWidth={4}
            strokeWidthSecondary={3}
          />
          {status === Status.Loading && (
            <Typography variant="h5" style={styles.label}>
              ログイン中...
            </Typography>
          )}
          {status === Status.GetInfo && (
            <Typography variant="h5" style={styles.label}>
              ユーザ確認中...
            </Typography>
          )}
          {status === Status.UpdateInfo && (
            <Typography variant="h5" style={styles.label}>
              ユーザ情報更新中...
            </Typography>
          )}
          {status === Status.Error && (
            <Box>
              <Typography>ログインに失敗しました</Typography>
              <Typography>
                {errorMessage ?? "管理者にお問い合わせください"}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
})

export default AuthHook
