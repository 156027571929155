import dayjs from "dayjs";

const MemoDataState = {
    thread: null,
    threadId: null,
    threadTitle: null,
    isOpen: null,
    update: dayjs().unix(),
}

export default MemoDataState
