import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import { AgGridReact } from "ag-grid-react"
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"
import { RootDataContext } from "../index"
import LOCALE_JA from "../../../resources/aggrid/locale.ja"
import APIManager from "../../../manager/api"
import {
  DoubleArrow as DoubleArrowIcon,
  Filter1 as Filter1Icon,
  Filter2 as Filter2Icon,
  Filter3 as Filter3Icon,
  Filter4 as Filter4Icon,
  Filter5 as Filter5Icon,
  Filter6 as Filter6Icon,
  Filter7 as Filter7Icon,
  Filter8 as Filter8Icon,
  Filter9 as Filter9Icon,
  Filter9Plus as Filter9PlusIcon,
  FilterNone as FilterNoneIcon,
} from "@mui/icons-material"
import { Component } from "ag-grid-community"
import "ag-grid-enterprise"
import Encoding from "encoding-japanese"
import JSZip from "jszip"
import { saveAs } from "file-saver"
import dayjs from "dayjs"

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #fff",
    flexGrow: 1,
  },
  title: {
    color: "white",
    margin: "4px !important",
  },
  grid: {
    flexGrow: 1,
  },
}

let lastReq = null
let totalCount = 0
let lastRenderPage = 0

const RootKosatenListView = (props) => {
  const { setViewType, setMapDetailInfo, setShowMemoData } =
    useContext(RootDataContext)
  const { state } = useContext(RootDataContext)
  const gridRef = useRef()
  const [rowData, setRowData] = useState(null)
  const [downloading, setDownloading] = useState(null)
  const [downloadRate, setDownloadRate] = useState(0.0)
  const [movePage, setMovePage] = useState()

  useEffect(() => {
    lastRenderPage = 0
  }, [])

  setTimeout(() => {
    if (
      gridRef.current &&
      lastRenderPage &&
      gridRef.current.api.paginationGetCurrentPage() !== lastRenderPage
    ) {
      gridRef.current.api.paginationGoToPage(lastRenderPage)
      lastRenderPage = 0
    }
  }, 2000)

  const columnDef = useMemo(
    () => [
      {
        headerName: "地図",
        cellRenderer: (param) => {
          return (
            <IconButton
              size="small"
              onClick={() => {
                setMapDetailInfo({
                  lngLat: [param.data.longitude, param.data.latitude],
                  uuid: param.data.uuid,
                  type: "kosaten",
                  moveTo: true,
                })
              }}
            >
              <DoubleArrowIcon style={{ color: "#666" }} fontSize="small" />
            </IconButton>
          )
        },
        width: 60,
        sortable: false,
      },
      { field: "rn", headerName: "順位", filter: "agNumberColumnFilter" },
      {
        field: "risk",
        headerName: "リスクレベル",
        filter: "agNumberColumnFilter",
      },
      {
        field: "intersection_name",
        headerName: "交差点名",
        filter: "agTextColumnFilter",
      },
      {
        field: "thread_count",
        headerName: "メモスレッド数",
        filter: "agNumberColumnFilter",
        cellRenderer: (param) => {
          return (
            <Typography
              variant="body2"
              style={{ textDecoration: "underline", color: "#304dd3" }}
            >
              {param.value}
            </Typography>
          )
        },
      },
      {
        field: "comment_last_updated_at_jst",
        headerName: "メモ更新日時",
        valueFormatter: (params) => {
          if (params.value) {
            return dayjs(params.value).format("YYYY/MM/DD HH:mm:ss")
          }
          return null
        },
        filter: false,
        menuTabs: [],
      },
      {
        field: "address",
        headerName: "周辺住所",
        filter: "agTextColumnFilter",
      },
      {
        field: "elementary_school_name",
        headerName: "学区",
        filter: false,
        menuTabs: [],
      },
      { field: "zone30", headerName: "ゾーン30", filter: "agTextColumnFilter" },
      {
        field: "speed",
        headerName: "規制速度（km/h）",
        filter: "agTextColumnFilter",
      },
      {
        field: "speed_median",
        headerName: "走行速度（km/h）",
        filter: "agNumberColumnFilter",
        valueFormatter: (params) => params.value?.toFixed(1),
      },
      {
        field: "pt0_2020",
        headerName: "人口指数（人）",
        filter: "agNumberColumnFilter",
      },
    ],
    []
  )

  const getData = useCallback(
    (params) => {
      if (!params) {
        return
      }
      let req = {
        ...params.request,
        format: "list",
        mode: state.predictionMode,
        search_comment:
          (state.searchComment ?? "").length > 0
            ? encodeURI(state.searchComment)
            : null,
      }
      // school_elementary_name: state.schoolArea?.name,
      if (state.schoolArea) {
        req.filterModel["elementary_school_name"] = {
          filterType: "text",
          type: "equals",
          filter: state.schoolArea.name,
        }
      }
      lastReq = req
      APIManager.getKosatenPredictionList(req)
        .then((res) => {
          console.log(res)
          totalCount = res.rowCount
          params.success(res)
        })
        .catch((e) => {
          console.log(e)
          params.fail()
        })
    },
    [state.searchComment, state.schoolArea, state.predictionMode]
  )

  const downloadGeoJson = useCallback(async () => {
    setOpenDownloadMenu(false)
    setDownloading("ダウンロード準備中")
    let DATA_LIMIT = 5000
    let rows = []
    for (let i = 0; i < totalCount; i += DATA_LIMIT) {
      let req = { ...lastReq }
      req.startRow = i
      req.endRow = i + DATA_LIMIT
      let res = await APIManager.getKosatenPredictionList({
        ...req,
        format: "geojson",
      })
      rows = [
        ...rows,
        ...Array.from(res.rowData).map((row) => JSON.parse(row.j)),
      ]
      console.log(rows)
      setDownloadRate((i + DATA_LIMIT) / totalCount)
    }
    setDownloading("ダウンロードします")
    let geoJson = {
      type: "FeatureCollection",
      features: rows,
    }

    const textData = JSON.stringify(geoJson)

    const zip = new JSZip()
    zip.file(`risk_assesment_intersection.geojson`, textData)
    const content = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9,
      },
    })
    saveAs(content, `risk_assesment_intersection.zip`)
    setTimeout(() => {
      setDownloading(false)
    }, 1500)
  }, [])

  const downloadCSV = useCallback(async () => {
    setOpenDownloadMenu(false)
    setDownloading("ダウンロード準備中")
    let rows = []
    let DATA_LIMIT = 10000
    let dataColumns = null

    let keyOrders = [
      "rn",
      "risk",
      "intersection_name",
      "address",
      "elementary_school_name",
      "zone30",
      "speed",
      "speed_median",
      "pt0_2020",
      "thread_count",
    ]
    let nameOrders = keyOrders.map((k) => {
      let col = columnDef.filter((v) => v.field === k)
      if (!col || col.length === 0) {
        return null
      }
      return col[0].headerName
    })

    for (let i = 0; i < totalCount; i += DATA_LIMIT) {
      let req = { ...lastReq }
      req.startRow = i
      req.endRow = i + DATA_LIMIT
      let res = await APIManager.getKosatenPredictionList({
        ...req,
        format: "csv",
      })
      rows = [...rows, ...res.rowData]
      if (!dataColumns && rows.length > 0) {
        dataColumns = Object.keys(rows[0])
      }
      setDownloadRate((i + DATA_LIMIT) / totalCount)
    }
    setDownloading("ダウンロードします")

    let textData =
      nameOrders.map((t) => `"${t}"`).join(",") +
      "\r\n" +
      rows.map((v) => keyOrders.map((k) => (v[k] === null || v[k] === "null") ? `""`: `"${v[k]}"`).join(",")).join("\r\n")

    let bom = new Uint8Array([0xef, 0xbb, 0xbf])
    let blob = new Blob([bom, textData], { type: "text/csv" })
    const zip = new JSZip()
    zip.file(`risk_assesment_intersection.csv`, blob)
    const content = await zip.generateAsync({
      type: "blob",
      compression: "DEFLATE",
      compressionOptions: {
        level: 9,
      },
    })
    saveAs(content, `risk_assesment_intersection.zip`)
    setTimeout(() => {
      setDownloading(false)
    }, 1500)
  }, [])

  useEffect(() => {
    if (!downloading) {
      setDownloadRate(0)
      console.log(gridRef.current?.api?.refreshServerSide)
      //      gridRef.current?.api?.refreshServerSide()
    }
  }, [downloading])

  const downloadButtonRef = useRef()

  const [openDownloadMenu, setOpenDownloadMenu] = useState(false)

  const onCloseDownloadMenu = () => {
    setOpenDownloadMenu(false)
  }

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={styles.title}>交差点事故評価</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box
          sx={{
            marginRight: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => setOpenDownloadMenu(true)}
            style={{ fontSize: "11px" }}
            ref={downloadButtonRef}
          >
            ダウンロード
          </Button>
        </Box>
      </Box>
      <AgGridReact
        localeText={LOCALE_JA}
        className={"ag-theme-balham"}
        containerStyle={styles.grid}
        ref={gridRef}
        columnDefs={columnDef}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
          menuTabs: ["filterMenuTab"],
        }}
        rowModelType="serverSide"
        serverSideDatasource={{
          getRows(params) {
            console.log("[KosatenList]", "[GetRow]")
            if (downloading) {
              return params.fail()
            }
            getData(params)
          },
        }}
        pagination={true}
        paginationAutoPageSize={true}
        onPaginationChanged={(event) => {
          if (!event.keepRenderedRows && event.newPage) {
            lastRenderPage = event.api.paginationGetCurrentPage()
          }
        }}
        onCellClicked={(e) => {
          if (e.column.getColId() === "thread_count") {
            setShowMemoData({
              type: "kosaten",
              uuid: e.data.uuid,
            })
          }
          console.log(e)
        }}
        overlayLoadingTemplate={
          '<span style="padding: 8px 16px; border: 1px solid #000; border-radius: 4px;"><img src="./loading.gif" style="width: 26px; vertical-align: middle">読込中・・・</span>'
        }
        overlayNoRowsTemplate={
          '<span style="padding: 8px 16px; border: 1px solid #000; border-radius: 4px;">対象データなし</span>'
        }
      />
      {downloading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: "1000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography>{downloading}</Typography>
            <CircularProgress
              variant="determinate"
              value={downloadRate > 1 ? 100 : downloadRate * 100}
            />
          </Box>
        </Box>
      )}
      <Menu
        id="kosaten-download-menu"
        aria-labelledby="kosaten-download-button"
        anchorEl={downloadButtonRef?.current}
        open={openDownloadMenu}
        onClose={onCloseDownloadMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={downloadCSV}>CSV</MenuItem>
        <MenuItem onClick={downloadGeoJson}>GeoJSON</MenuItem>
        <MenuItem onClick={onCloseDownloadMenu}>キャンセル</MenuItem>
      </Menu>
    </Box>
  )
}

RootKosatenListView.propTypes = {
  sx: PropTypes.object,
  filter: PropTypes.object,
}

export default RootKosatenListView
