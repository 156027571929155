import { useCallback, useEffect, useState } from "react"

const useResize = ({ defaultWidth, minWidth, maxWidth }) => {
  const [isResizing, setIsResizing] = useState(false)
  const [width, setWidth] = useState(defaultWidth ?? minWidth)

  const enableResize = useCallback(() => {
    setIsResizing(true)
    document.body.style.userSelect = 'none'
  }, [setIsResizing])

  const disableResize = useCallback(() => {
    setIsResizing(false)
    document.body.style.userSelect = ''
  }, [setIsResizing])

  const resize = useCallback(
    (e) => {
      if (isResizing) {
        const newWidth = window.innerWidth -  e.clientX // You may want to add some offset here from props
        if (newWidth >= minWidth && (!maxWidth || newWidth < maxWidth)) {
          setWidth(newWidth)
        }
      }
    },
    [minWidth, isResizing, setWidth]
  )

  useEffect(() => {
    document.addEventListener("mousemove", resize)
    document.addEventListener("mouseup", disableResize)

    return () => {
      document.removeEventListener("mousemove", resize)
      document.removeEventListener("mouseup", disableResize)
    }
  }, [disableResize, resize])

  return { width, enableResize }
}

export default useResize
