import React, { useCallback, useContext, useEffect, useMemo } from "react"
import {Box, Drawer, IconButton, Typography} from "@mui/material"
import { useState } from "react"
import MemoThreadView from "./thread"
import MemoCommentView from "./comment"
import { RootDataContext } from "../index"
import APIManager from "../../../manager/api"
import useResize from "../../useResize"
import UseMemoViewData from "./data"

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
  thread: {
    flexGrow: 1,
    maxWidth: "300px",
  },
  comment: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}
export const MemoDataContext = React.createContext()

// eslint-disable-next-line react/display-name
const MemoView = React.memo((props) => {
  const { state, setShowMemoData } = useContext(RootDataContext)
  const { width, enableResize } = useResize({
    defaultWidth: window.innerWidth / 2,
    minWidth: window.innerWidth / 3,
    maxWidth: window.innerWidth / 1.5,
  })
  const useMemoData = UseMemoViewData()
  const { clearData } = useMemoData

  const open = useMemo(() => {
    if (!state.showMemoData) {
      clearData()
    }

    return !!state.showMemoData
  }, [state.showMemoData])
  const onClose = () => {
    setShowMemoData(null)
  }

  useEffect(() => {
    console.log("Update width", width)
  }, [width])

  return (
    <MemoDataContext.Provider value={useMemoData}>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        style={styles.dragger}
        PaperProps={{ style: { width } }}
      >
        <Box sx={{ ...styles.root, ...(enableResize && { useSelect: false, userSelect: false }) }}>
          <MemoThreadView style={styles.thread} />
          <MemoCommentView style={styles.comment} />
        </Box>
        <div
          style={{
            position: "absolute",
            width: "8px",
            top: "0",
            left: "-4px",
            bottom: "0",
            cursor: "col-resize",
          }}
          onMouseDown={enableResize}
        />
      </Drawer>
    </MemoDataContext.Provider>
  )
})

export default MemoView
