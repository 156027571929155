import PropTypes from "prop-types"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useAuth0 } from "@auth0/auth0-react"
import APIManager from "../../../manager/api"

const styles = {
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        width: "600px",
        maxWidth: "80%",
        height: "300px",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "4px 4px 16px",
    },
}

const RootResetPasswordView = (props) => {
  const { getAccessTokenSilently } = useAuth0()

  const [showPassword, setShowPassword] = useState(false)
  const [showRePassword, setShowRePassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [password, setPassword] = useState("")
  const [rePassword, setRePassword] = useState("")

  const message = useMemo(() => {
    if (props.first) {
      return "初期パスワードを変更してください"
    }

    return "パスワードの変更"
  }, [props.first, props.force])

  const submit = async () => {
    APIManager.resetPassword(password, rePassword)
      .then((res) => {
        props.onChanged(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <Box sx={styles.root}>
      <Typography variant="h6" sx={{ mt: 1 }}>
        パスワードの変更
      </Typography>
      <Typography variant="subtitle2">{message}</Typography>


        <TextField
            label="新しいパスワード"
            id="outlined-new-password"
            sx={{ m: 1, width: '400px' }}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={()=> setShowPassword(!showPassword)} edge="end" tabIndex={-1}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>,
            }}
            type={showPassword ? "text": "password"}
            value={password}
            onChange={e => setPassword(e.target.value)}
        />

        <TextField
            label="再入力"
            id="outlined-re-input"
            sx={{m: 1, width: "400px"}}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => setShowRePassword(!showRePassword)} edge="end" tabIndex={-1}>
                        {showRePassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </InputAdornment>
            }}
            type={showRePassword ? "text": "password"}
            value={rePassword}
            onChange={e => setRePassword(e.target.value)}
        />

      {errorMessage && <Typography>{errorMessage}</Typography>}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {!props.force && <Button>キャンセル</Button>}
        <Button
          variant="outlined"
          disabled={password.length < 2 || password != rePassword}
          onClick={() => submit()}
        >
          {props.first ? "登録" : "変更"}
        </Button>
      </Box>
    </Box>
  )
}

RootResetPasswordView.propTypes = {
  first: PropTypes.bool,
  force: PropTypes.bool,
  onClose: PropTypes.func,
  onChanged: PropTypes.func,
}

export default RootResetPasswordView
