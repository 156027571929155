import PropTypes from "prop-types"
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material"
import FilterSelectorView from "./filterSelector"
import React, { useContext } from "react"
import { RootDataContext } from "../../index"
import {
  Circle as CheckOnIcon,
  CircleOutlined as CheckOffIcon,
} from "@mui/icons-material"

const styles = {
  root: {
    width: "200px",
    minWidth: "200px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  group: {
    backgroundColor: "#f3f3f3",
    padding: "8px",
  },
  box: {
    backgroundColor: "white",
    margin: "8px 8px",
    height: "30px",
  },
  checkGroup: {
    margin: "0 8px",
  },
}

const timeValues = [
  { label: "朝：7-10時", value: [7, 8, 9] },
  { label: "昼：10-16時", value: [10, 11, 12, 13, 14, 15] },
  { label: "夕：16-20時", value: [16, 17, 18, 19] },
  { label: "夜：20-7時", value: [20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6] },
]

const ageValues = [
  { label: "0~24歳", value: "0~24歳" },
  { label: "25~34歳", value: "25~34歳" },
  { label: "35~44歳", value: "35~44歳" },
  { label: "45~54歳", value: "45~54歳" },
  { label: "55~64歳", value: "55~64歳" },
  { label: "65~74歳", value: "65~74歳" },
  { label: "75歳以上", value: "75歳以上" },
]

const typeValues = [
  { label: "車両単独", value: "車両単独" },
  { label: "車両相互", value: "車両相互" },
  { label: "人対車両", value: "人対車両" },
  { label: "軽車両単独", value: "軽車両単独" },
  { label: "軽車両相互", value: "軽車両相互" },
  { label: "軽車両対車両", value: "軽車両対車両" },
  { label: "人対軽車両", value: "人対軽車両" },
//  { label: "列車", value: "列車" },
]

const injuryValues = [
  { label: "死亡", value: "死亡" },
  { label: "負傷", value: "負傷" },
  { label: "損傷なし", value: "損傷なし" },
  { label: "対象外当事者", value: "対象外当事者" },
]

const weatherValues = [
  { label: "晴", value: ["晴", "曇"] },
  { label: "雨", value: ["雨"] },
]

const viewDataValues = [
  { label: "道路事故評価", value: "道路事故評価" },
  { label: "交差点事故評価", value: "交差点事故評価" },
  { label: "事故実績", value: "事故実績" },
  { label: "行政界", value: "行政界" },
  { label: "小学校学区", value: "小学校学区" },
]

let checkboxTimer = null

const RootLeftPanelView = (props) => {
  const {
    state,
    setTimeslotFilter,
    setAgeFilter,
    setTypeFilter,
    setInjuryFilter,
    setWeatherFilter,
    toggleViewData,
  } = useContext(RootDataContext)

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      {/* 左ペイン */}
      {state.viewData.includes("事故実績") && (
        <Box sx={styles.group}>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ marginTop: "4px" }}
          >
            事故実績
          </Typography>
          <Divider
            flexItem={true}
            sx={{
              margin: "0 50px 15px 50px",
              borderWidth: "1.5px",
              borderColor: "#3c6960",
              backgroundColor: "#3c6960",
            }}
          />
          <FilterSelectorView
            sx={styles.box}
            name="時間帯"
            values={timeValues}
            value={state.timeslotFilter}
            onChange={(v) => {
              setTimeslotFilter(v)
            }}
          />
          <FilterSelectorView
            sx={styles.box}
            name="年齢帯"
            values={ageValues}
            value={state.ageFilter}
            onChange={(v) => {
              setAgeFilter(v)
            }}
          />
          <FilterSelectorView
            sx={styles.box}
            name="事故種類"
            values={typeValues}
            value={state.typeFilter}
            onChange={(v) => {
              setTypeFilter(v)
            }}
          />
          <FilterSelectorView
            sx={styles.box}
            name="事故程度"
            values={injuryValues}
            value={state.injuryFilter}
            onChange={(v) => {
              setInjuryFilter(v)
            }}
          />
          <FilterSelectorView
            sx={styles.box}
            name="天候"
            values={weatherValues}
            value={state.weatherFilter}
            onChange={(v) => {
              setWeatherFilter(v)
            }}
          />
        </Box>
      )}
      <Box sx={styles.group}>
        <Typography
          variant="subtitle2"
          align="center"
          style={{ marginTop: "16px" }}
        >
          表示データ選択
        </Typography>
        <Divider
          flexItem={true}
          style={{
            margin: "0 50px 15px 50px",
            borderWidth: "1.5px",
            borderColor: "#3c6960",
            backgroundColor: "#3c6960",
          }}
        />
        <FormGroup sx={styles.checkGroup}>
          {viewDataValues.map((e, i) => {
            return (
              <FormControlLabel
                sx={{
                  margin: 0,
                  height: "34px",
                }}
                key={i}
                control={
                  <Checkbox
                    sx={{ color: "#3c6960", transform: "scale(0.9)" }}
                    defaultChecked={state.viewData.includes(e.value)}
                    icon={
                      <CheckOffIcon
                        color="#3c6960 !important"
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckOnIcon
                        color="#3c6960 !important"
                        fontSize="small"
                      />
                    }
                  />
                }
                onClick={() => {
                  clearTimeout(checkboxTimer)
                  checkboxTimer = setTimeout(() => {
                    toggleViewData(e.value)
                  }, 10)
                }}
                label={<Typography variant="subtitle2">{e.label}</Typography>}
              />
            )
          })}
        </FormGroup>
      </Box>
    </Box>
  )
}

RootLeftPanelView.propTypes = {
  sx: PropTypes.object,
}

export default RootLeftPanelView
