import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Box, Divider, IconButton, TextField, Typography } from "@mui/material"
import { MemoDataContext } from "../index"
import { RootDataContext } from "../../index"
import APIManager from "../../../../manager/api"
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Clear as ClearIcon,
  Done as DoneIcon,
  Edit as EditIcon,
} from "@mui/icons-material"
import InputView from "./input"
import MemoView from "./memo"
import dayjs from "dayjs"

const styles = {
  root: {
    overflowY: "scroll",
  },
  headerBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "46px",
    margin: "4px 8px",
  },
  threadBox: {},
  threadTitleBox: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  threadTitle: { fontWeight: "800", fontSize: "17px" },
  noThreadTitle: {},
  titleEditBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  titleInput: {
    flexGrow: 1,
  },
  doneIcon: {
    color: "#4c6bd0",
  },
  cancelIcon: {
    color: "#c95b5b",
  },
  openLabel: {
    fontSize: "12px",
    fontStyle: "normal",
    border: "1px solid #666",
    borderRadius: "4px",
    padding: "2px 8px",
    marginLeft: "8px",
    whiteSpace: "nowrap",
  },
  openLabel_close: {
    backgroundColor: "#999",
    color: "#fff",
  },
  openLabel_open_other: {
    cursor: "normal",
    backgroundColor: "white",
    color: "#006c60",
  },
  openLabel_open_owner: {
    cursor: "pointer",
    backgroundColor: "#006c60",
    color: "white",
  },
}

const weekday = ["日", "月", "火", "水", "木", "金", "土"]

let dateLabel = null

const MemoCommentListView = (props) => {
  const { state, openStateDialog } = useContext(RootDataContext)
  const {
    state: memoState,
    setThread,
    setThreadTitle,
    dataUpdated,
  } = useContext(MemoDataContext)
  const [editingTitle, setEditingTitle] = useState(false)
  const [editingMemo, setEditingMemo] = useState(false)
  const [title, setTitle] = useState(null)

  const thread = useMemo(() => {
    console.log('[CommentList]', 'updated', memoState.thread)
    return memoState.thread
  }, [memoState.update, memoState.thread])

  useEffect(() => {
    if (!memoState.threadId) {
      setTitle(null)
    }

    setTitle(memoState.threadTitle)
  }, [memoState.threadTitle, memoState.update])

  const [list, setList] = useState([])

  useEffect(() => {
    console.log("[CommentList]", "update", memoState.threadId, memoState.update)
    APIManager.getMemoComments(memoState.threadId)
      .then((res) => {
        setList(res)
      })
      .catch((e) => {
        console.log(e)
        setList([])
      })
  }, [memoState.threadId, memoState.update])

  const onEditTitle = () => {
    setEditingTitle(true)
  }

  const onTitleChanged = () => {
    if (memoState.threadId) {
      APIManager.updateMemoThread(memoState.threadId, title)
        .then((res) => {
          setThreadTitle(title)
          setEditingTitle(false)
          dataUpdated()
        })
        .catch((e) => {})
    } else {
      setThreadTitle(title)
      setEditingTitle(false)
    }
  }


  const openLabelStyle = useMemo(() => {
    if (!memoState.thread) {
      return {display: "none"}
    }

    if (!memoState.thread.is_open) {
      return {
        ...styles.openLabel,
        ...styles.openLabel_close,
        cursor: thread.is_editable ? "pointer" : "default",
      }
    }
    if (memoState.thread.is_editable) {
      return {
        ...styles.openLabel,
        ...styles.openLabel_open_owner,
        cursor: thread.is_editable ? "pointer" : "default",
      }
    }
    return { ...styles.openLabel, ...styles.openLabel_open_other, cursor: "default" }
  }, [memoState.thread, thread, memoState.update])

  const toggleOpen = () => {
    if (!memoState.thread.is_editable) {
      return
    }

    openStateDialog({
      title: `スレッドの${thread.is_open ? "非公開" : "公開"}`,
      content: (
        <Typography>
          このスレッドを{thread.is_open ? "非公開" : "公開"}にしますか
        </Typography>
      ),
      actions: [
        {
          title: "キャンセル",
          onClick: () => {
            console.log("キャンセル")
          },
        },
        {
          title: memoState.thread.is_open ? "非公開" : "公開",
          color: memoState.thread.is_open ? "secondary" : "primary",
          onClick: () => {
            APIManager.setMemoOpen(memoState.threadId, !thread.is_open, {
              type: "thread",
            })
              .then((res) => {
                setThread(res)
              })
              .catch((e) => {
                console.log(e)
              })
          },
        },
      ],
    })
  }

  useEffect(() => {
    console.log("Update", memoState.thread)
  }, [memoState.update, memoState.thread])

  const onTitleCanceled = () => {
    setTitle(memoState.threadTitle)
    setEditingTitle(false)
  }

  return (
    <Box sx={{ ...styles.root, ...props.style }}>
      <Box sx={styles.headerBox}>
        {editingTitle && (
          <Box sx={styles.titleEditBox}>
            <TextField
              style={styles.titleInput}
              size="small"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="スレッドタイトル"
            />
            <IconButton size="small">
              <CheckCircleIcon
                style={styles.doneIcon}
                onClick={onTitleChanged}
              />
            </IconButton>
            <IconButton onClick={onTitleCanceled} size="small">
              <CancelIcon style={styles.cancelIcon} />
            </IconButton>
          </Box>
        )}
        {!editingTitle && !memoState.threadId && !title && (
          <Typography style={{ ...styles.threadTitle, color: "#656565" }}>
            新規スレッド
          </Typography>
        )}
        {!editingTitle && !memoState.threadId && title && (
          <Typography style={{ ...styles.threadTitle, color: "#afafaf" }}>
            {title}
          </Typography>
        )}
        {!editingTitle && memoState.threadId && (
          <Typography style={styles.threadTitle}>
            {memoState.threadTitle}
          </Typography>
        )}
        {memoState.thread?.is_editable && !editingTitle && !editingMemo && (
          <Box>
            <IconButton size="small" onClick={onEditTitle}>
              <EditIcon />
            </IconButton>
          </Box>
        )}
        {thread && (
          <Box
            style={openLabelStyle}
            onClick={toggleOpen}
          >
            {thread.is_open ? "公開" : "非公開"}
          </Box>
        )}
      </Box>
      <Divider style={{ margin: "0 8px" }} />
      <Box>
        {list.map((comment, idx) => {
          console.log(idx, comment)
          return (
            <>
              {(!editingMemo || editingMemo !== comment.comment_id) && (
                <MemoView
                  key={`viwe|${comment.comment_id}|${comment.updated_at_jst}`}
                  idx={idx}
                  data={comment}
                  onEdit={() => setEditingMemo(comment.comment_id)}
                />
              )}
              {editingMemo && editingMemo === comment.comment_id && (
                <InputView
                  key={`input|${comment.comment_id}|${comment.updated_at_jst}`}
                  idx={idx}
                  data={comment}
                  onClose={() => setEditingMemo(null)}
                />
              )}
            </>
          )
        })}
      </Box>
    </Box>
  )
}

MemoCommentListView.propTypes = {
  style: PropTypes.object,
}

export default MemoCommentListView
