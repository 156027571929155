import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import { Rows } from "../../../manager/carto"
import { RootDataContext } from "../index"
import APIManager from "../../../manager/api"
import { useAuth0 } from "@auth0/auth0-react"

const RootPlaceSearchView = (props) => {
  const { user } = useAuth0()
  const {state, setMapCenter, setSchoolArea } = useContext(RootDataContext)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (!state?.user) {
      return
    }

    APIManager.getGakku().then((res) => {
      setOptions(res)
    })
  }, [state?.user])

  return (
    <Autocomplete
      sx={props.sx}
      disablePortal
      size="small"
      onChange={(e, v) => {
        if (v) {
          setSchoolArea({
            lat: v.latitude,
            lng: v.longitude,
            name: v.name,
            polygon: v.bounds_box,
          })
        } else {
          setSchoolArea(null)
        }
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography variant="caption">{option.label}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="学区検索"
          color="primary"
          InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}
          InputLabelProps={{ style: { fontSize: 12 } }}
        />
      )}
      options={options}
    />
  )
}

RootPlaceSearchView.propTypes = {
  sx: PropTypes.object,
}

export default RootPlaceSearchView
