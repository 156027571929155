import { useReducer } from "react"
import reducer, { ActionType } from "./reducer"
import initialState from "./state"

const UseListViewData = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const setViewType = (value) => {
    dispatch({ type: ActionType.SetViewType, value: value })
  }

  return {
    state,
    setViewType,
  }
}

export default UseListViewData
