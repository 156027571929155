import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { RootDataContext } from "../../../index"
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import APIManager from "../../../../../manager/api"

const styles = {
  root: {
    backgroundColor: "#f3f3f3",
    color: "black",
    padding: "12px",
  },
  chart: {
    marginTop: "12px",
    backgroundColor: "white",
    width: "calc(100% - 8px)",
    borderRadius: "4px",
  },
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip)

const options = {
  responsive: true,
  interaction: {
    intersect: false,
    axis: "x",
  },
  plugins: {},
}

const labels = [
  "0.1",
  "0.2",
  "0.3",
  "0.4",
  "0.5",
  "0.6",
  "0.7",
  "0.8",
  "0.9",
  "1.0",
]

const RootKousatenPredictCard = (props) => {
  const { state } = useContext(RootDataContext)
  const [data, setData] = useState(null)

  useEffect(() => {
    APIManager.getPredictKosatenData(
      state.predictionMode,
      state.rangeType,
      state.mapViewBounds,
      9
    ).then((rows) => {
      let vals = new Array(9).fill(0)
      rows?.forEach((v) => {
        vals[v.num] = v.value
      })
      setData(vals)
    })
  }, [state.predictionMode, state.rangeType, state.mapViewBounds])

  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <Typography>交差点事故発生リスク(箇所)</Typography>
      {data && (
        <Bar
          style={styles.chart}
          type="line"
          options={options}
          data={{
            labels: labels,
            datasets: [
              {
                label: "指数",
                data: data,
                borderColor: "#69e5c0",
                backgroundColor: "#69e56e",
                fill: false,
                stepped: true,
              },
            ],
          }}
        />
      )}
    </Box>
  )
}

RootKousatenPredictCard.propTypes = {
  sx: PropTypes.object,
}

export default RootKousatenPredictCard
