import React, {useMemo} from "react"
import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import {MapDetailInfoDefaultStyles} from "../../../../../styles";


const styles = {...MapDetailInfoDefaultStyles}

// eslint-disable-line
// eslint-disable-next-line react/display-name
const MapDetailInfoJissekiDetailView = React.memo((props) => {

  // eslint-disable-next-line no-undef
  const data = useMemo(() => {
    if (!props.data) { return null }
    return {
      ...props.data
    }
  }, [props.data])

  return (
    <Box>
      <Typography>実績詳細データ</Typography>
      <Box>
        <table style={styles.table}>
        <tr><th style={styles.th}>事故種類</th><td style={styles.td}>{data.accident_type_jp}</td></tr>
        <tr><th style={styles.th}>発生日</th><td style={styles.td}>{data.case_ymd}</td></tr>
        <tr><th style={styles.th}>時間帯</th><td style={styles.td}>{data.hour_name_jp}</td></tr>
        <tr><th style={styles.th}>道路種</th><td style={styles.td}>{data.road_formation_jp}</td></tr>
        <tr><th style={styles.th}>発生場所</th><td style={styles.td}>{data.road_formation_jp}</td></tr>
        <tr><th style={styles.th}>歩車道区分</th><td style={styles.td}>{data.pedestrian_separation_jp}</td></tr>
        <tr><th style={styles.th}>環境</th><td style={styles.td}>{data.topography_jp}</td></tr>
        <tr><th style={styles.th}>天候</th><td style={styles.td}>{data.weather_jp}</td></tr>
        <tr><th style={styles.th}>路面状態</th><td style={styles.td}>{data.road_condition_jp}</td></tr>
        <tr><th style={styles.th}>事故程度</th><td style={styles.td}>{data.personal_injury_all}</td></tr>
        <tr><th style={styles.th}>年齢帯</th><td style={styles.td}>{data.personal_injury_all_age}</td></tr>
      </table>
      </Box>
    </Box>
  )
})

MapDetailInfoJissekiDetailView.propTypes = {
  data: PropTypes.object,
}

export default MapDetailInfoJissekiDetailView
