import React, { useContext, useEffect, useMemo, useState } from "react"
import { Box } from "@mui/material"
import APIManager from "../../../../../manager/api"
import { RootDataContext } from "../../../index"
import { Chart } from "react-chartjs-2"

const RootRiskHistGraphDouroView = (props) => {
  const { state } = useContext(RootDataContext)

  const [data, setData] = useState()
  const labels = useMemo(() => {
    return Array.from(Array(10).keys()).map((i) => i + 2023)
  }, [])
  const options = useMemo(() => {
    return {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            title: (xDataPoint) => {
              console.log(xDataPoint.dataset)
              if ((xDataPoint?.length ?? 0) === 0) {
                return ""
              }
              switch (xDataPoint[0].dataset?.type) {
                case "line":
                  return "実績"
                case "bar":
                  return "リスク値"
                default:
                  break
              }
              return ""
            },
          },
        },
      },
      scales: {
        y1: {
          type: "linear",
          display: true,
          position: "left",
          min: 0,
          max: 1,
        },
        y2: {
          type: "linear",
          display: true,
          position: "right",
          min: 0,
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              if (value % 1 === 0) {
                // if integer, return it as is
                return value
              }
            },
          },
        },
      },
    }
  }, [])

  useEffect(() => {
    if (!state.mapDetailInfo?.uuid) {
      return
    }

    console.log("[Kosaten]", state.mapDetailInfo.uuid)

    APIManager.getDouroRiskHist(state.mapDetailInfo.uuid, state.predictionMode)
      .then((res) => {
        console.log("[Hist]", "result", res)

        let val1 = Object.fromEntries(
          res.map((v) => [v.year_represent, v.prob])
        )
        let val2 = Object.fromEntries(res.map((v) => [v.year_represent, v.ach]))

        let data1 = Array.from(labels.map((k) => val1[k] ?? 0))
        let data2 = Array.from(labels.map((k) => val2[k] ?? null))
        console.log("[Hist]", "data", data1, data2)

        setData({
          labels,
          datasets: [
            {
              type: "line",
              data: data2,
              borderWidth: 2,
              borderColor: "#4459de",
              fill: false,
              yAxisID: "y2",
            },
            {
              type: "bar",
              data: data1,
              borderWidth: 1,
              borderColor: "#69e5c0",
              backgroundColor: "#69e56e",
              yAxisID: "y1",
            },
          ],
        })
      })
      .catch((e) => {})
  }, [state.mapDetailInfo?.uuid, state.predictionMode])
  // const data = useMemo(async () => {
  //
  // }, [state.mapDetailInfo?.data, state.predictionMode])

  return (
    <Box
      sx={{
        height: "100px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8pt",
              height: "12px",
              color: "#5b5b5b",
              position: "absolute",
              bottom: "24px",
              width: "100%",
            }}
          >
            {" "}
            <p>リスク</p>
            <p>実績</p>
          </Box>
          <Chart data={data} options={options} height={100} width={220} />
        </Box>
      )}
    </Box>
  )
}

export default RootRiskHistGraphDouroView
