import React, { useContext, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button, Typography,
} from "@mui/material"
import DouroListView from "./douro"
import KosatenListView from "./kosaten"

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-balham.css"
import UseListViewData from "./data"
import { ListViewType } from "./data/state"

const styles = {
  root: {
    backgroundColor: "#7eab55",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  list: {
    border: "1px solid #fff",
    flexGrow: 1,
  },
  selected: {
    "& .Mui-checked": {
      color: "yellow",
      backgroundColor: "red",
    },
    "& .MuiSwitch-track": {
      color: "purple",
      backgroundColor: "red",
    },
  },
}

export const ListDataContext = React.createContext()

// eslint-disable-next-line react/display-name
export const NoRowsView = React.memo(() => {
  return (
      <Box>
        <Typography>該当データなし</Typography>
      </Box>
  )
})

// eslint-disable-next-line react/display-name
const ListView = React.memo((props) => {
  const useListData = UseListViewData()
  const { state, setViewType } = useListData
  const [type, setType] = useState("road")

  console.log('ListView render with', props);

  const handleChange = (e) => {
    setViewType(e.target.value)
  }

  const filter = useMemo(() => {
    return {
      elementary_school_name: state.schoolArea?.name,
    }
  }, [state.schoolArea])

  return (
    <ListDataContext.Provider value={useListData}>
      <Box sx={{ ...styles.root, ...props.sx }} className="ag-theme-alpine">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="text"
              sx={{
                borderRadius: 0,
                color: "white",
                backgroundColor:
                  state.viewType === ListViewType.douro ? "#1a5b23" : "#749457",
              }}
              onClick={() => setViewType(ListViewType.douro)}
            >
              AIリスク評価
              <br />
              （道路）
            </Button>
            <Button
              variant="text"
              style={{
                borderRadius: 0,
                color: "white",
                backgroundColor:
                  state.viewType === ListViewType.kousaten
                    ? "#1a5b23"
                    : "#749457",
              }}
              onClick={() => setViewType(ListViewType.kousaten)}
            >
              AIリスク評価
              <br />
              （交差点）
            </Button>
          </Box>
        </Box>
        {state.viewType === ListViewType.douro && (
          <DouroListView />
        )}
        {state.viewType === ListViewType.kousaten && (
          <KosatenListView />
        )}
      </Box>
    </ListDataContext.Provider>
  )
})

ListView.propTypes = {
  sx: PropTypes.object,
}

export default ListView
