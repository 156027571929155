import { Box } from "@mui/material"
import TitleView from "./title"
import PredictionModeSelectorView from "./predictionModeSelector"
import RangeTypeSelectorView from "./rangeTypeSelector"
import React from "react"
import PropTypes from "prop-types"

const RootHeaderView = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#f3f3f3",
        display: "flex",
        flexDirection: "row",
        ...props.sx,
      }}
    >
      <TitleView sx={{ flexGrow: 1 }} />
    </Box>
  )
}

RootHeaderView.propTypes = {
  sx: PropTypes.object,
}

export default RootHeaderView
