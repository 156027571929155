import { First, One, Query } from "./carto"
import { RangeType } from "../views/root/data/state"
import APIManager from "./api"

export const predictionModeValues = [
  { label: "全事故", value: "global" },
  { label: "朝方", value: "mor" },
  { label: "昼間", value: "day" },
  { label: "夕方", value: "eve" },
  { label: "夜間", value: "nig" },
  { label: "未成年", value: "stud" },
  { label: "成人", value: "adlt" },
  { label: "高齢者", value: "elde" },
]

export default new (class {
  constructor() {}

  IsEmpty = (val) => {
    return val === undefined || val === null
  }

  _defaultOpt_GetStringValue = {
    throwIfEmpty: false,
    emptyIfZeroLength: true,
    default: null,
  }
  GetStringValue = (val, options = this._defaultOpt_GetStringValue) => {
    options = { ...this._defaultOpt_GetStringValue, ...options }

    if (val === "" && options.emptyIfZeroLength) {
      if (options.throwIfEmpty) {
        throw "valid is zero length"
      }
      val = options.default
    } else if (this.IsEmpty(val)) {
      if (options.throwIfEmpty) {
        throw "value is empty"
      }
      val = options.default
    }
    if (val === undefined || val === null) {
      return "null"
    }

    let str = val
      .replaceAll("'", "")
      .replaceAll(";", "")
      .replaceAll("\n", "<br />")
    return `'${str}'`
  }

  _defaultOpt_GetBoolValue = {
    throwIfEmpty: false,
    falseIfZeroLength: false,
    default: null,
  }
  GetBoolValue = (val, options = this._defaultOpt_GetBoolValue) => {
    options = { ...this._defaultOpt_GetBoolValue, ...options }

    if (this.IsEmpty(val)) {
      if (options.throwIfEmpty) {
        throw "value is empty"
      }
      val = options.default
    }
    if (val === "" && options.falseIfZeroLength) {
      val = 0
    }

    if (val === undefined || val === null || val === "") {
      return "null"
    }

    if (typeof val === "string") {
      if (val.toLowerCase() === "true" || val.toLowerCase() === "yes") {
        return "True"
      } else if (val.toLowerCase() === "false" || val.toLowerCase() === "no") {
        return "False"
      }
    }

    return val ? "True" : "False"
  }

  _defaultOpt_GetNumValue = {
    throwIfEmpty: false,
    zeroIfZeroLength: false,
    default: null,
    fixed: null,
  }
  GetNumValue = (val, options = this._defaultOpt_GetNumValue) => {
    options = { ...this._defaultOpt_GetNumValue, ...options }

    if (this.IsEmpty(val)) {
      if (options.throwIfEmpty) {
        throw "value is empty"
      }
      val = options.default
    }
    if (val === "" && options.zeroIfZeroLength) {
      val = 0
    }
    if (val === undefined || val === null || val === "") {
      return "null"
    }
    if (isNaN(val)) {
      if (options.throwIfEmpty) {
        throw "value is not number"
      }
      return "null"
    }
    if (options.fixed !== null && isNaN(options.fixed)) {
      return parseFloat(val).toFixed(parseInt(options.fixed))
    }
    return `${val}`
  }

  getFilterConditions = (filter) => {
    let wheres = []
    if (filter.timeslot) {
      wheres.push(
        `case_hour in (${Array.from(filter.timeslot)
          .map((v) => this.GetNumValue(v))
          .join(",")})`
      )
    }
    if (filter.age) {
      wheres.push(
        `trim(personal_injury_all_age) = ${this.GetStringValue(filter.age)}`
      )
    }
    if (filter.type) {
      wheres.push(
        `trim(accident_type_jp) = ${this.GetStringValue(filter.type)}`
      )
    }
    if (filter.injury) {
      wheres.push(
        `trim(personal_injury_all) = ${this.GetStringValue(filter.injury)}`
      )
    }
    if ((filter.weather?.length ?? 0) > 0) {
      //      console.log("[FilterWeather]", filter.weather)
      wheres.push(
        `trim(weather_jp) in (${Array.from(filter.weather)
          .map((v) => this.GetStringValue(v))
          .join(",")})`
      )
    }
    return wheres
  }

  getBoundsConditions = (
    rangeType,
    bounds,
    column = "the_geom_webmercator"
  ) => {
    if (rangeType === RangeType.Overall) {
      return "TRUE"
    }

    let latlngs = []
    latlngs.push(`${bounds[0].lng} ${bounds[0].lat}`)
    latlngs.push(`${bounds[0].lng} ${bounds[1].lat}`)
    latlngs.push(`${bounds[1].lng} ${bounds[1].lat}`)
    latlngs.push(`${bounds[1].lng} ${bounds[0].lat}`)
    latlngs.push(`${bounds[0].lng} ${bounds[0].lat}`)
    return `
    ST_Contains(ST_Transform(ST_SetSRID(ST_MakePolygon('LINESTRING(${latlngs.join(
      ","
    )})'),4326),3857),${column})
    `
  }

  GetPrefCityCondition = (userInfo, options = {}) => {
    let prefColumn = options.pref ?? "pref_code"
    let cityColumn = options.city ?? "n03_007"
    let values
    switch (userInfo.type) {
      case "city":
        values = Array.from(userInfo.city_code)
          .map((v) => this.GetNumValue(v))
          .filter((v) => !!v)
          .join(",")
        return `${cityColumn}::int IN (${values})`
      case "pref":
        values = Array.from(userInfo.pref_code)
          .map((v) => this.GetNumValue(v))
          .filter((v) => !!v)
          .join(",")
        return `${prefColumn}::int IN (${values})`
      default:
        break
    }

    return null
  }

  //
  // selectQueryPop = (user, mode) => {
  //   let column
  //   if (mode === "global") {
  //     column = "global"
  //   } else {
  //     column = `pop_${mode}`
  //   }
  //   return `
  //   SELECT data.*, ${column} as pop FROM ${process.env.REACT_APP_TABLE_POP} AS data
  //   INNER JOIN ${process.env.REACT_APP_TABLE_GYOSEIKU} AS admin
  //   ON ST_Intersects(admin.the_geom_webmercator, data.the_geom_webmercator)
  //   WHERE admin.n03_007 = '${process.env.REACT_APP_CITYCODE}'
  //   `
  // }

  // selectQueryComment = (user) => {
  //   return `SELECT DISTINCT cartodb_id, the_geom, the_geom_webmercator, type FROM ${user.env.VIEW_COMMENT} WHERE city_code = ${process.env.REACT_APP_CITYCODE}`
  // }

  selectQueryGakku = (user) => {
    let wheres = [
      this.GetPrefCityCondition(user, { pref: "pref_code", city: "n03_007" }),
    ]

    /* eslint-disable-next-line linebreak-style */
    let q = `
      SELECT cartodb_id, the_geom, the_geom_webmercator, elementary_school_name AS a27_007 FROM ${
        user.env.TABLE_GAKKU
      }
      WHERE ${wheres.join(" AND ")}
    `

    console.log("[School Layer]", q)

    return q
  }

  selectQueryGyoseikai = (user) => {
    return `SELECT cartodb_id, the_geom, the_geom_webmercator FROM ${user.env.TABLE_CITY_POLYGON}`
  }

  selectQueryDourojikoyosoku = (
    user,
    mode_column = "global",
    schoolArea = null,
    options = {}
  ) => {
    let wheres = ["TRUE"]

    if (schoolArea) {
      wheres.push(`elementary_school_name = '${schoolArea}'`)
    }
    wheres.push(this.GetPrefCityCondition(user))

    if (options.risk_idx) {
      wheres.push(`risk_idx_${mode_column} in (${options.risk_idx.join(", ")})`)
    }

    // prettier-ignore
    let q = `
      SELECT 
        ${user.env.UUID_COLUMN_DOURO} AS uuid,
        cartodb_id,
        lane_count,
        acc_${mode_column}_risk_${process.env.REACT_APP_TABLE_PREDICTION_COLUMN_SUFFIX} as risk,
        the_geom, the_geom_webmercator
      FROM ${user.env.TABLE_PREDICTION_DOURO}
      WHERE ${wheres.join(" AND ")}
    `

    return q
  }
  //
  // selectQueryAverageYosoku = (
  //   user,
  //   rangeType,
  //   bounds,
  //   mode_column = "global",
  //   options = {}
  // ) => {
  //   if (rangeType === RangeType.ViewBounds && !bounds) {
  //     return null
  //   }
  //
  //   let wheres = [
  //     this.getBoundsConditions(rangeType, bounds, "data.the_geom_webmercator"),
  //   ]
  //
  //   // prettier-ignore
  //   let q = `
  //   SELECT AVG(risk) AS avg FROM (
  //      SELECT acc_${mode_column}_risk_${process.env.REACT_APP_TABLE_PREDICTION_COLUMN_SUFFIX} AS risk
  //      FROM ${user.env.TABLE_PREDICTION_KOSATEN} AS data
  //      WHERE ${wheres.join(" AND ")}
  //      UNION ALL
  //      SELECT acc_${mode_column}_risk_${process.env.REACT_APP_TABLE_PREDICTION_COLUMN_SUFFIX} AS risk
  //      FROM ${user.env.TABLE_PREDICTION_DOURO} AS data
  //      WHERE ${wheres.join(" AND ")}
  //                                ) AS foo
  //   `
  //
  //   //console.log(q)
  //
  //   return q
  // }

  selectQueryKosatenjikoYosoku = (
    user,
    mode_column = "global",
    schoolArea = null,
    options = {}
  ) => {
    let wheres = ["TRUE"]

    if (schoolArea) {
      wheres.push(`elementary_school_name = '${schoolArea}'`)
    }
    wheres.push(this.GetPrefCityCondition(user))

    if (options.risk_idx) {
      wheres.push(`risk_idx_${mode_column} in (${options.risk_idx.join(", ")})`)
    }

    // prettier-ignore
    return `
    SELECT 
      acc_${mode_column}_risk_${process.env.REACT_APP_TABLE_PREDICTION_COLUMN_SUFFIX} as risk, 
      the_geom, 
      the_geom_webmercator, 
      cartodB_id,
      lane_count,
      ${user.env.UUID_COLUMN_KOSATEN} AS uuid
    FROM ${user.env.TABLE_PREDICTION_KOSATEN} AS data
    WHERE ${wheres.join(" AND ")}
    `
  }

  selectQueryJikojisseki = (user, filter, schoolArea, options = {}) => {
    let wheres = this.getFilterConditions(filter)

    wheres.push(this.GetPrefCityCondition(user))

    if (schoolArea) {
      wheres.push(`elementary_school_name = '${schoolArea}'`)
    }

    let sql = `
      WITH cte AS (
        SELECT elementary_school_name, d.* FROM ${user.env.TABLE_JISSEKI} AS d
        LEFT JOIN ${user.env.TABLE_GAKKU} AS e
        ON ST_Contains(e.the_geom_webmercator, d.the_geom_webmercator)
      )
      SELECT
          ${user.env.UUID_COLUMN_JISSEKI} AS uuid,
          cartodb_id,
          the_geom,
          the_geom_webmercator
      FROM cte
      WHERE ${wheres.join(" AND ")}
    `

    console.log("Jisseki SQL", sql, schoolArea)

    return sql
  }
})()
