export const MapDetailInfoDefaultStyles = {
  table: {
    backgroundColor: "white",
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    fontSize: "12px",
    borderColor: "transparent transparent #444 transparent",
    borderStyle: "none none dotted none",
    borderWidth: "0 0 1px 0",
    whiteSpace: "nowrap",
    textAlign: "left",
    marginLeft: "8px",
  },
  td: {
    fontSize: "12px",
    borderColor: "transparent transparent #444 transparent",
    borderStyle: "none none dotted none",
    borderWidth: "0 0 1px 0",
  },
  commentBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  commentButton: {
    margin: "8px",
    width: "100%",
    fontSize: "9pt",
    backgroundColor: "#006c60",
  },
}
