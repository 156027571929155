// Example locale file for English, give this to your locale team to translate

const LOCALE_JA = {
  // Set Filter
  selectAll: "(全選択)",
  selectAllSearchResults: "(すべての検索結果を選択)",
  searchOoo: "検索...",
  blanks: "(ブランク)",
  noMatches: "一致なし",

  // Number Filter & Text Filter
  filterOoo: "フィルター...",
  equals: "一致",
  notEqual: "不一致",
  empty: "未選択",
  blank: "空白",
  notBlank: "空白を除く",

  // Number Filter
  lessThan: "未満",
  greaterThan: "超過",
  lessThanOrEqual: "以下",
  greaterThanOrEqual: "以上",
  inRange: "範囲内",
  inRangeStart: "開始",
  inRangeEnd: "終了",

  // Text Filter
  contains: "含む",
  notContains: "含まない",
  startsWith: "先頭一致",
  endsWith: "末尾一致",

  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",

  // Filter Conditions
  andCondition: "かつ",
  orCondition: "または",

  // Filter Buttons
  applyFilter: "適用",
  resetFilter: "リセット",
  clearFilter: "クリア",
  cancelFilter: "キャンセル",

  // Filter Titles
  textFilter: "テキストフィルタ",
  numberFilter: "数字フィルタ",
  dateFilter: "データフィルタ",
  setFilter: "フィルタ設定",

  // Side Bar
  columns: "表示項目",
  filters: "フィルタ",

  // columns tool panel
  pivotMode: "ピポットモード",
  groups: "行グループ",
  rowGroupColumnsEmptyMessage: "ドラッグして行グループにセット",
  values: "値",
  valueColumnsEmptyMessage: "ドラッグして合計にセット",
  pivots: "行ラベル",
  pivotColumnsEmptyMessage: "ドラッグして行ラベルにセット",

  // Header of the Default Group Column
  group: "グループ",

  // Other
  loadingOoo: "読込中...",
  noRowsToShow: "表示データがありません",
  enabled: "有効",

  // Menu
  pinColumn: "固定列",
  pinLeft: "左固定",
  pinRight: "右固定",
  noPin: "固定解除",
  valueAggregation: "合計値",
  autosizeThiscolumn: "自動幅調整",
  autosizeAllColumns: "全自動幅調整",
  groupBy: "グループ化",
  ungroupBy: "グループ解除",
  resetColumns: "列リセット",
  expandAll: "すべて展開",
  collapseAll: "すべて折りたたむ",
  copy: "コピー",
  ctrlC: "Ctrl+C",
  copyWithHeaders: "コピー(列名を含む)",
  paste: "貼り付け",
  ctrlV: "Ctrl+V",
  export: "エクスポート",
  csvExport: "CSVエクスポート",
  excelExport: "Excelエクスポート",

  // Enterprise Menu Aggregation and Status Bar
  sum: "合計",
  min: "最小",
  max: "最大",
  none: "なし",
  count: "個数",
  avg: "平均",
  filteredRows: "フィルター行数",
  selectedRows: "選択行数",
  totalRows: "全行数",
  totalAndFilteredRows: "行数",
  more: "More",
  to: "〜",
  of: "/",
  page: "ページ",
  nextPage: "次ページ",
  lastPage: "最終ページ",
  firstPage: "最初のページ",
  previousPage: "前のページ",

  // Pivoting
  pivotColumnGroupTotals: "合計",

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "ピポットグラフとモード",
  pivotChart: "ピポットグラフ",
  chartRange: "ピポット範囲",

  columnChart: "列",
  groupedColumn: "グループ列",
  stackedColumn: "積上げ行",
  normalizedColumn: "100% 積上げ",

  barChart: "棒グラフ",
  groupedBar: "グループ",
  stackedBar: "積上げ",
  normalizedBar: "100% 積上げ",

  pieChart: "円グラフ",
  pie: "円",
  doughnut: "ドーナッツ",

  line: "線グラフ",

  xyChart: "2軸チャート",
  scatter: "散布図",
  bubble: "バブル",

  areaChart: "面グラフ",
  area: "麺",
  stackedArea: "積上げ",
  normalizedArea: "100% 積上げ",

  histogramChart: "ヒストグラム",

  // Charts
  pivotChartTitle: "ピポットチャート",
  rangeChartTitle: "範囲グラフ",
  settings: "設定",
  data: "データ",
  format: "形式",
  categories: "カテゴリ",
  defaultCategory: "(なし)",
  series: "順序",
  xyValues: "XY値",
  paired: "2値モード",
  axis: "軸",
  navigator: "ナビゲーター",
  color: "色",
  thickness: "太さ",
  xType: "Xの種類",
  automatic: "自動",
  category: "カテゴリ",
  number: "数値",
  time: "時間",
  xRotation: "X回転",
  yRotation: "Y回転",
  ticks: "目盛り",
  width: "幅",
  height: "高さ",
  length: "長さ",
  padding: "余白",
  spacing: "間隔",
  chart: "グラフ",
  title: "タイトル",
  titlePlaceholder: "グラフタイトル - ダブルクリックで編集",
  background: "背景",
  font: "フォント",
  top: "上",
  right: "右",
  bottom: "下",
  left: "左",
  labels: "ラベル",
  size: "大きさ",
  minSize: "最小サイズ",
  maxSize: "最大サイズ",
  legend: "凡例",
  position: "位置",
  markerSize: "マーカーの大きさ",
  markerStroke: "マーカーの太さ",
  markerPadding: "マーカーの余白",
  itemSpacing: "間隔",
  itemPaddingX: "余白(横)",
  itemPaddingY: "余白(縦)",
  layoutHorizontalSpacing: "横の間隔",
  layoutVerticalSpacing: "縦の間隔",
  strokeWidth: "線の太さ",
  offset: "オフセット",
  offsets: "オフセット",
  tooltips: "注釈",
  callout: "吹き出し",
  markers: "マーカー",
  shadow: "影",
  blur: "ブラー",
  xOffset: "X位置",
  yOffset: "Y位置",
  lineWidth: "幅",
  normal: "標準",
  bold: "ボールド",
  italic: "イタリック",
  boldItalic: "ボールドイタリック",
  predefined: "定義済み",
  fillOpacity: "透明度",
  strokeOpacity: "透明度(線)",
  histogramBinCount: "区画数",
  columnGroup: "列グループ",
  barGroup: "棒フラグ",
  pieGroup: "円グラフ",
  lineGroup: "線グラフ",
  scatterGroup: "2軸グラフ",
  areaGroup: "面グラフ",
  histogramGroup: "ヒストグラム",
  groupedColumnTooltip: "グループ",
  stackedColumnTooltip: "積上げ",
  normalizedColumnTooltip: "100% 積上げ",
  groupedBarTooltip: "グループ",
  stackedBarTooltip: "積上げ",
  normalizedBarTooltip: "100% 積上げ",
  pieTooltip: "円フラグ",
  doughnutTooltip: "ドーナッツ",
  lineTooltip: "線グラフ",
  groupedAreaTooltip: "面グラフ",
  stackedAreaTooltip: "積上げグラフ",
  normalizedAreaTooltip: "100% 積上げ",
  scatterTooltip: "散布図",
  bubbleTooltip: "バブル",
  histogramTooltip: "ヒストグラム",
  noDataToChart: "チャートに表示するデータがありません",
  pivotChartRequiresPivotMode:
    "ピポットテーブルにするにはピポットモードにする必要があります",
  chartSettingsToolbarTooltip: "メニュー",
  chartLinkToolbarTooltip: "グリッドにリンク",
  chartUnlinkToolbarTooltip: "グリッドのリンク解除",
  chartDownloadToolbarTooltip: "グラフダウンロード",

  // ARIA
  ariaHidden: "非表示",
  ariaVisible: "表示",
  ariaChecked: "チェック",
  ariaUnchecked: "非チェック",
  ariaIndeterminate: "不確定",
  ariaColumnSelectAll: "選択されたセルを変更",
  ariaInputEditor: "エディタ",
  ariaDateFilterInput: "日付入力",
  ariaFilterInput: "フィルタ入力",
  ariaFilterColumnsInput: "列フィルタ",
  ariaFilterValue: "フィルター値",
  ariaFilterFromValue: "値からフィルタ",
  ariaFilterToValue: "値へフィルタ",
  ariaFilteringOperator: "フィルタ操作",
  ariaColumn: "列",
  ariaColumnGroup: "列グループ",
  ariaRowSelect: "スペースを押して行を選択",
  ariaRowDeselect: "スペースを押して行の選択解除",
  ariaRowToggleSelection: "スペースを押して行の選択/非選択切り替え",
  ariaRowSelectAll: "スペースを押してすべての行を選択",
  ariaToggleVisibility: "スペースを押して表示/非表示切り替え",
  ariaSearch: "検索",
  ariaSearchFilterValues: "検索フィルタ値",

  // ARIA LABEL FOR DIALOGS
  ariaLabelCellEditor: "セル編集",
  ariaLabelDialog: "ダイアログ",
  ariaLabelSelectField: "フィールド選択",
  ariaLabelTooltip: "注釈",
  ariaLabelContextMenu: "右クリックメニュー",
  ariaLabelColumnMenu: "セルメニュー",
  ariaLabelSubMenu: "サブメニュー",
  ariaLabelAggregationFunction: "集計関数",
}

export default LOCALE_JA
