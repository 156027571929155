import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Box, IconButton, Typography } from "@mui/material"
import {
  Add as AddIcon,
  Layers as LayersIcon,
  Remove as RemoveIcon,
} from "@mui/icons-material"
import maplibregl from "maplibre-gl"
import PropTypes from "prop-types"
import APIManager from "../../../../manager/api"
import PredictKosatenLayer, {
  Order as PredictKosatenOrder,
    MAJOR_LAYER_NAME as predictKosatenLayerName,
} from "./layers/predictKosaten"
import PredictDouroLayer, {
  Order as PredictDouroOrder,
    MAJOR_LAYER_NAME as predictDouroLayerName
} from "./layers/predictDouro"
import JissekiLayer, { Order as JissekiOrder, MAJOR_LAYER_NAME as jissekiLayerName } from "./layers/jisseki"
import GyoseikaiLayer, { Order as GyoseikaiOrder, MAJOR_LAYER_NAME as gyoseikaiLayerName } from "./layers/gyouseikai"
import SchoolLayer, { Order as SchoolOrder, MAJOR_LAYER_NAME as schoolLayerName } from "./layers/school"

import DouroLegends from "./legend/douro"
import KousatenLegends from "./legend/kousaten"
import JinryuLegends from "./legend/jinryu"
import JissekiLegends from "./legend/jiseki"

import { RootDataContext } from "../../index"
import DataManager from "../../../../manager/data"
import "./road_detail_table.css"

const baseLayers = {
  dark: {
    name: "ダークモード",
    url: "https://b.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
  },
  standard: {
    name: "地理院地図（標準）",
    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
  },
  pale: {
    name: "地理院地図（淡色）",
    url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
  },
  photo: {
    name: "地理院地図（写真）",
    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
  },
}

const styles = {
  legends: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    position: "absolute",
    left: "12px",
    top: "12px",
    zIndex: 3,
  },
}

const BASE_SOURCE_NAME = "raster-tiles"
const BASE_LAYER_NAME = "raster-tiles-layer"

export const LAYERS_CONFIG = [
  {
    name: "level1",
    zoom: { maxzoom: 9 },
    riskIndex: [1],
    rightSidePanelHidden: true,
  },
  {
    name: "level2",
    zoom: { minzoom: 9, maxzoom: 11 },
    riskIndex: [1, 2],
    rightSidePanelHidden: true,
  },
  {
    name: "level3",
    zoom: { minzoom: 11, maxzoom: 12 },
    riskIndex: [1, 2, 3, 4],
    rightSidePanelHidden: true,
  },
  {
    name: "level4",
    zoom: { minzoom: 12, maxzoom: 14 },
    riskIndex: [1, 2, 3, 4, 5, 6, 7],
    rightSidePanelHidden: true,
  },
  {
    name: "level5",
    zoom: { minzoom: 14 },
  },
]

let token = null
let marker
let popup

let clickDataDouro
let clickDataKosaten
let clickDataJisseki
let clickDataTimer

const MapView = (props) => {
  const mapRef = useRef()
  const [map, setMap] = useState()
  const {
    state,
    setMapViewBounds,
    setPinLocation,
    setMapCenter,
    setMapDetailInfo,
  } = useContext(RootDataContext)
  const [openBaseLayerMenu, setOpenBaseLayerMenu] = useState(false)
  const [baseLayer, setBaseLayer] = useState(baseLayers.pale)
  const [hideLegends, setHideLegends] = useState(false)

  // const [clickJissekiData, setClickJissekiData] = useState()
  // const [clickDouroData, setClickDouroData] = useState()
  // const [clickKosatenData, setClickKosatenData] = useState()
  const [clickCommentData, setClickCommentData] = useState()

  const [predictDouroLoaded, setPredictDouroLoaded] = useState(false)
  const [predictKosatenLoaded, setPredictKosatenLoaded] = useState(true)
  const [jissekiLoaded, setJissekiLoaded] = useState(true)
  const [gyoseikaiLoaded, setGyoseikaiLoaded] = useState(false)
  const [schoolLoaded, setSchoolLoaded] = useState(false)

  const clickedRef = useRef()
  const clickedTimerRef = useRef()

  const clickedDouroDataRef = useRef()
  const clickedKosatenDataRef = useRef()
  const clickedJissekiDataRef = useRef()

  const lastLoadUuidRef = useRef()

  useEffect(() => {
    let inv = setInterval(() => {
      APIManager.accessToken().then((t) => {
        token = t
      })
    }, 1000)

    return () => {
      clearInterval(inv)
    }
  }, [token])

  useEffect(() => {
    if (!mapRef || !mapRef.current || !state.user) {
      return
    }

    console.log("[Map]", "initailize", "state.user", state.user)

    const m = new maplibregl.Map({
      container: mapRef.current,
      pitchWithRotate: false,
      dragRotate: false,
      customAttribution: `© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, © <a href="https://carto.com/about-carto/">CARTO</a>`,
      attributionControl: true,
      minZoom: 6,
      maxZoom: 20,
      style: {
        glyphs: "https://maps.gsi.go.jp/xyz/noto-jp/{fontstack}/{range}.pbf",
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [baseLayer.url],
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "base-tiles-layer",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      ...state.user?.map_init,
      transformRequest: (url, resourceType) => {
        if (url.match(/.mvt$/)) {
          //          console.log(url, resourceType)
          return {
            url: url,
            headers: {
              Accept: "application/x-protobuf, appliction/json, text/html",
              AuthorizationToken: `Bearer ${APIManager.lastToken}`,
              "accept-encoding": "gzip, deflate, br",
            },
          }
        }
        return null
      },
    })

    const scale = new maplibregl.ScaleControl({
      maxWidth: 80,
      unit: "metric",
    })
    m.addControl(scale)

    m.on("load", () => {
      setMap(m)
      setMapViewBounds([
        m.getBounds().getNorthEast(),
        m.getBounds().getSouthWest(),
      ])
    })
      .on("moveend", () => {
        //        console.log(m.getBounds())
        setMapViewBounds([
          m.getBounds().getNorthEast(),
          m.getBounds().getSouthWest(),
        ])
        setMapCenter({ ...m.getCenter(), zoom: m.getZoom() })
      })
      .on("click", () => {
        setMapDetailInfo(null)
        marker?.remove()
        marker = null
      })

    return () => {
      map?.remove()
      setMap(null)
    }
  }, [mapRef, state.user])

  useEffect(() => {
    clickDataDouro = null
    clickDataJisseki = null
    clickDataKosaten = null
    clickDataTimer && clearTimeout(clickDataTimer)
  }, [state.mapDetailInfo])

  useEffect(() => {
    if (!map || !baseLayer || !baseLayer.url) {
      return
    }

    map.getSource(BASE_SOURCE_NAME).tiles = [baseLayer.url]
    map.style.sourceCaches[BASE_SOURCE_NAME].clearTiles()
    map.style.sourceCaches[BASE_SOURCE_NAME].update(map.transform)
    map.triggerRepaint()
  }, [map, baseLayer])

  const updateClickData = useCallback((data) => {
    clickedRef.current = data

    switch (data.type) {
      case "douro":
        clickedDouroDataRef.current = data
        break
      case "kosaten":
        clickedKosatenDataRef.current = data
        break
      case "jisseki":
        clickedJissekiDataRef.current = data
        break
    }

    clickedTimerRef.current && clearTimeout(clickedTimerRef.current)

    clickedTimerRef.current = setTimeout(() => {
      let type
      let uuid
      let lngLat
      let func
      if (clickedJissekiDataRef.current) {
        console.log("ClickData", "lngLat", clickedJissekiDataRef.current.lngLat)
        uuid = clickedJissekiDataRef.current.uuid
        lngLat = clickedJissekiDataRef.current.lngLat
        type = "jisseki"
        func = APIManager.getJissekiDetailData
      } else if (clickedKosatenDataRef.current) {
        console.log("ClickData", "lngLat", clickedKosatenDataRef.current.lngLat)
        uuid = clickedKosatenDataRef.current.uuid
        lngLat = clickedKosatenDataRef.current.lngLat
        type = "kosaten"
        func = APIManager.getKosatenDetailData
      } else if (clickedDouroDataRef.current) {
        console.log("ClickData", "lngLat", clickedDouroDataRef.current.lngLat)
        uuid = clickedDouroDataRef.current.uuid
        lngLat = clickedDouroDataRef.current.lngLat
        type = "douro"
        func = APIManager.getDouroDetailData
      }

      clickedKosatenDataRef.current = null
      clickedDouroDataRef.current = null
      clickedJissekiDataRef.current = null

      setMapDetailInfo({ uuid, type, lngLat })
      func(uuid, state.predictionMode).then((data) => {
        console.log("[MapDetail]", "change map detail info by click", data)
        setMapDetailInfo({ uuid, type, lngLat, data, moveTo: false })
      })
    }, 300)
  }, [])

  useEffect(() => {
    if (!map || !state.mapDetailInfo) {
      return
    }

    marker?.remove()

    marker = new maplibregl.Marker()
      .setLngLat(state.mapDetailInfo.lngLat)
      .addTo(map)
    if (state.mapDetailInfo.moveTo) {
      map.flyTo({
        center: state.mapDetailInfo.lngLat,
        essential: true,
        zoom: state.mapDetailInfo.zoom ?? 18,
      })
    }
  }, [map, state.mapDetailInfo])
  //
  // const updateClickData = useCallback(
  //   (data) => {
  //     if (!map) {
  //       return
  //     }
  //     clickedRef.current = data
  //
  //     setMapDetailInfo(null)
  //     console.log(data)
  //     switch (data.type) {
  //       case "douro":
  //         clickDataDouro = data
  //         break
  //       case "kosaten":
  //         clickDataKosaten = data
  //         break
  //       case "jisseki":
  //         clickDataJisseki = data
  //         break
  //       default:
  //         return
  //     }
  //
  //     clickDataTimer && clearTimeout(clickDataTimer)
  //
  //     clickDataTimer = setTimeout(() => {
  //       let type
  //       let uuid
  //       let lngLat
  //       let func
  //       if (clickDataJisseki) {
  //         console.log("ClickData", "lngLat", clickDataJisseki.lngLat)
  //         uuid = clickDataJisseki.uuid
  //         lngLat = clickDataJisseki.lngLat
  //         type = "jisseki"
  //         func = APIManager.getJissekiDetailData
  //       } else if (clickDataKosaten) {
  //         console.log("ClickData", "lngLat", clickDataKosaten.lngLat)
  //         uuid = clickDataKosaten.uuid
  //         lngLat = clickDataKosaten.lngLat
  //         type = "kosaten"
  //         func = APIManager.getKosatenDetailData
  //       } else if (clickDataDouro) {
  //         console.log("ClickData", "lngLat", clickDataDouro.lngLat)
  //         uuid = clickDataDouro.uuid
  //         lngLat = clickDataDouro.lngLat
  //         type = "douro"
  //         func = APIManager.getDouroDetailData
  //       }
  //
  //       clickDataKosaten = null
  //       clickDataDouro = null
  //       clickDataJisseki = null
  //
  //
  //       console.log("[MapDetail]", "click event occuerd", uuid, type, lngLat)
  //       setMapDetailInfo({ uuid, type, lngLat })
  //       func(uuid, state.predictionMode).then((data) => {
  //         console.log('[MapDetail]', 'change map detail info by click', data)
  //         setMapDetailInfo({ uuid, type, lngLat, data, moveTo: false })
  //       })
  //     }, 300)
  //   },
  //   [map, state.predictionMode]
  // )

  useEffect(() => {
    if (!map || !predictKosatenLoaded || !predictDouroLoaded || !gyoseikaiLoaded || !schoolLoaded || !jissekiLoaded) {
      return
    }
    setTimeout(() => {
      GyoseikaiOrder(map)
      SchoolOrder(map)
      PredictDouroOrder(map)
      PredictKosatenOrder(map)
      JissekiOrder(map)
    }, 3000)
  }, [
    map,
    predictKosatenLoaded,
    predictDouroLoaded,
    jissekiLoaded,
    gyoseikaiLoaded,
    schoolLoaded,
  ])

  useEffect(() => {
    if (!map) {
      return
    }

    map.resize()
  }, [map, state.visibleRightPane])

  useEffect(() => {
    if (!map || !state.schoolArea) {
      return
    }
    console.log(state.schoolArea)

    if (state.schoolArea.polygon) {
      let geojson = JSON.parse(state.schoolArea.polygon)
      let coordinates = geojson.coordinates[0]
      let bounds = new maplibregl.LngLatBounds(coordinates[0], coordinates[1])
      coordinates.map((c) => {
        bounds.extend(c)
      })
      map.fitBounds(bounds, {
        padding: 80,
      })
    } else {
      map.flyTo({
        center: [state.schoolArea.lng, state.schoolArea.lat],
        zoom: 15,
      })
    }
  }, [map, state.schoolArea])

  useEffect(() => {
    marker?.remove()
    popup?.remove()
  }, [
    state.viewData,
    state.timeslotFilter,
    state.ageFilter,
    state.typeFilter,
    state.injuryFilter,
    state.weatherFilter,
  ])

  const onZoomOut = () => {
    if (!map) {
      return
    }
    map.flyTo({ center: map.getCenter(), zoom: map.getZoom() - 1 })
  }

  const onZoomIn = () => {
    if (!map) {
      return
    }
    map.flyTo({ center: map.getCenter(), zoom: map.getZoom() + 1 })
  }

  return (
    <Box
      sx={{ position: "relative", width: "100%", height: "100%", ...props.sx }}
    >
      <Box
        ref={mapRef}
        sx={{ position: "relative", width: "100%", height: "100%" }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: "30px",
            left: "0",
            zIndex: "1",
            backgroundColor: "#ffffff4d",
            padding: "2px",
          }}
        >
          <Typography variant="small">
            「モバイル空間統計®」は（株）NTTドコモの登録商標です
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "32px",
            right: "8px",
            zIndex: "1",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            border: "1px solid #00000033",
          }}
        >
          <Box
            sx={{
              border: "1px solid #00000033",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton size="small" onClick={onZoomIn}>
              <AddIcon size="small" />
            </IconButton>
          </Box>
          <Box
            sx={{
              border: "1px solid #00000033",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-1px",
            }}
          >
            <IconButton size="small" onClick={onZoomOut}>
              <RemoveIcon size="small" />
            </IconButton>
          </Box>
        </Box>

        <PredictDouroLayer
          map={map}
          onLoad={() => setPredictDouroLoaded(true)}
          onClick={(e) => {
            updateClickData({
              type: "douro",
              lngLat: e.lngLat,
              ...e.features[0].properties,
            })
            // console.log("ClickDouroLayer", e.features)
            // setClickDouroData({ lngLat: e.lngLat, ...e.features[0].properties })
          }}
        />
        <PredictKosatenLayer
          map={map}
          onLoad={() => setPredictKosatenLoaded(true)}
          onClick={(e) => {
            console.log("Kosaten", "clicked", e.features[0])
            updateClickData({
              type: "kosaten",
              lngLat: e.lngLat,
              ...e.features[0].properties,
            })
          }}
        />
        <JissekiLayer
          map={map}
          onLoad={() => setJissekiLoaded(true)}
          onClick={(e) => {
            updateClickData({
              type: "jisseki",
              lngLat: e.lngLat,
              ...e.features[0].properties,
            })
          }}
        />
        <GyoseikaiLayer map={map} onLoad={() => setGyoseikaiLoaded(true)} />
        <SchoolLayer map={map} onLoad={() => setSchoolLoaded(true)} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
          width: "44px",
          height: "44px",
          backgroundColor: "white",
          borderRadius: "4px",
          border: "1px solid #777373",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onMouseEnter={() => {
          setOpenBaseLayerMenu(true)
        }}
      >
        <LayersIcon></LayersIcon>
      </Box>
      <Box
        sx={{
          display: openBaseLayerMenu ? "box" : "none",
          position: "absolute",
          top: "8px",
          right: "8px",
          backgroundColor: "white",
          border: "1px solid #000",
          borderRadius: "4px",
        }}
        onMouseLeave={() => {
          setOpenBaseLayerMenu(false)
        }}
      >
        <ul
          style={{
            listStyle: "none",
            padding: "8px",
            margin: 0,
            fontSize: "12px",
          }}
        >
          {Object.keys(baseLayers).map((k) => (
            <li key={k}>
              <label>
                <input
                  type="radio"
                  name="basemap"
                  checked={baseLayer.name === baseLayers[k].name}
                  onClick={() => setBaseLayer(baseLayers[k])}
                />
                {baseLayers[k].name}
              </label>
            </li>
          ))}
        </ul>
      </Box>
      {!hideLegends && (
        <Box sx={styles.legends}>
          {state.viewData.includes("道路事故評価") && <DouroLegends />}
          {state.viewData.includes("交差点事故評価") && <KousatenLegends />}
          {state.viewData.includes("人流・人口") && <JinryuLegends />}
          {state.viewData.includes("事故実績") && <JissekiLegends />}
        </Box>
      )}
    </Box>
  )
}

MapView.propTypes = {
  sx: PropTypes.object,
  mapOptions: PropTypes.any,
}

export default MapView
