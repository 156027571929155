import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  TextareaAutosize,
  Typography,
} from "@mui/material"

const RootHelpView = (props) => {
  const txt = useMemo(async () => {
    const res = await fetch("/terms-of-service.html")
    return await res.text()
  }, [])

  const [isAgreed, setIsAgreed] = useState(false)

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        margin: "0",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 5000,
        backgroundColor: "#0000006e",
        ...props.sx,
      }}
    >
      <Box
        style={{
          width: "20%",
          height: "20%",
          backgroundColor: "white",
          border: "1px solid #000",
          borderRadius: "8px",
          padding: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" style={{ textAlign: "center" }}>
          ヘルプ・お問い合わせ
        </Typography>
        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link href="/viewer_app_help_v01.pdf" target="_blank" rel="noopener">
            よくある質問・その他お問い合わせについて
          </Link>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button variant="contained" onClick={props.onClose}>
            閉じる
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

RootHelpView.propTypes = {
  sx: PropTypes.object,
  onClose: PropTypes.func,
}

export default RootHelpView
