import React, { useContext, useRef, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import PropTypes from "prop-types"
import { RootDataContext } from "../index"
import { RangeType } from "../data/state"

const RootOptionRangeTypeDialog = (props) => {
  const { state, setRangeType } = useContext(RootDataContext)
  const radioGroupRef = useRef()

  const handleClose = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const handleChange = (e) => {
    setRangeType(e.target.value)
    handleClose()
  }

  return (
    <Dialog onClose={handleClose} open={props.open}>
      <DialogTitle>集計範囲</DialogTitle>
      <DialogContent>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="range-type"
          name="range-type"
          value={state.rangeType}
          onChange={handleChange}
        >
          <FormControlLabel
            key={RangeType.ViewBounds}
            control={
              <Radio defaultChecked={state.rangeType === RangeType.ViewBounds} />
            }
            label="地図表示範囲"
            value={RangeType.ViewBounds}
          />
          <FormControlLabel
            key={RangeType.Overall}
            control={<Radio defaultChecked={state.rangeType === RangeType.Overall} />}
            label="市全体"
            value={RangeType.Overall}
          />
        </RadioGroup>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
      </DialogActions>
    </Dialog>
  )
}

RootOptionRangeTypeDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default RootOptionRangeTypeDialog
