import React, { useCallback, useContext, useState } from "react"
import { Box, Divider } from "@mui/material"
import CommentListView from "./list"
import CommentInputView from "./input"
import PropTypes from "prop-types"
import APIManager from "../../../../manager/api"
import { MemoDataContext } from "../index"
import { RootDataContext } from "../../index"

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 50px)",
  },
  list: {
    flexGrow: 1,
  },
  input: {},
}

const MemoCommentView = (props) => {
  const { state: memoState } = useContext(MemoDataContext)
  const { state } = useContext(RootDataContext)
  const [data, setData] = useState()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          paddingBottom: "8px",
          width: "100%",
          backgroundColor: "#006c60",
          boxShadow: "0 1px 8px -2px #414141",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "4px 0",
        }}
      >
        <Box
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            color: "white",
          }}
        >
          メモ
        </Box>
      </Box>
      <Box sx={{ ...styles.root, ...props.style }}>
        <CommentListView style={styles.list} data={data} />
        {(!memoState.thread ||
          memoState.thread.is_editable) && (
          <>
            <Divider style={{ margin: "0 8px" }} />
            <CommentInputView style={styles.input} data={null} />
          </>
        )}
      </Box>
    </Box>
  )
}

MemoCommentView.propTypes = {
  style: PropTypes.object,
}

export default MemoCommentView
