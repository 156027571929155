export const ActionType = {
  setUser: "SET_USER",
  setAccessToken: "SET_ACCESS_TOKEN",
}
const AuthDataReducer = (authState, action) => {
  switch (action.type) {
    case ActionType.setUser:
      return { ...authState, user: action.value }
    case ActionType.setAccessToken:
      return { ...authState, accessToken: action.value }
    default:
  }

  return authState
}

export default AuthDataReducer
