import axios from "axios"
axios.defaults.headers.post["Content-Type"] = "application/json"

const predictionModeValues = [
  { label: "グローバル", value: "global" },
  { label: "グローバル：晴", value: "sunny" },
  { label: "グローバル：雨", value: "rainy" },
  { label: "朝方", value: "mor" },
  { label: "昼間", value: "day" },
  { label: "夕方", value: "eve" },
  { label: "夜間", value: "nig" },
  { label: "未成年", value: "stud" },
  { label: "成人", value: "adlt" },
  { label: "高齢者", value: "elde" },
]

export default new (class {
  accessTokenFunc = null

  lastToken = null

  base64encode = (param) => {
    return encodeURIComponent(JSON.stringify(param))
  }

  cartApiBaseUrl = process.env.REACT_APP_CARTO_API_BASEURL.replace(/\/$/, "")
  authApiBaseUrl = process.env.REACT_APP_AUTH_API_BASEURL.replace(/\/$/, "")

  accessToken = async () => {
    if (!this.accessTokenFunc) {
      return null
    }
    let token = await this.accessTokenFunc()

    this.lastToken = token
    return token
  }

  headers = (token, otherHeaders) => {
    return {
      AuthorizationToken: `Bearer ${token}`,
      ...otherHeaders,
    }
  }

  getQueryString = (params) =>
    Object.keys(params)
      .map((k) => {
        //        console.log('queryString', k, params[k], typeof params[k])
        switch (typeof params[k]) {
          case "object":
            return `${k}=${encodeURI(JSON.stringify(params[k]))}`
          default:
            return `${k}=${encodeURI(params[k])}`
        }
      })
      .join("&")

  resetPassword = (password, rePassword) => {
    return new Promise((resolve, reject) => {
      this._post(
        `new_password`,
        {
          data: {
            new_password: password,
            re_new_password: rePassword,
          },
        },
        { auth: true }
      )
        .then(() => {
          window.location.reload()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  policyAgreed = (version) => {
    return new Promise((resolve, reject) => {
      this._postOne(`policy_aggreed`, version, { auth: true })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  userInfo = () => this._getOne(`info`, { auth: true })

  updateUserInfo = (data) => this._postOne("user_info", data)

  uploadImage = async (file) => {
    let baseUrl = this.cartApiBaseUrl

    return new Promise((resolve, reject) => {
      this.accessToken().then((token) => {
        this.fileConvertToBase64(file)
          .then((data) => {
            const formData = new FormData()
            formData.append("image", data)

            axios
              .post(`${baseUrl}/image`, formData, {
                headers: this.headers(token, {
                  "Content-Type": "multipart/form-data",
                }),
              })
              .then((res) => {
                if (res.data.error) {
                  reject(res.data.error)
                  return
                }
                if (!res.data.rows || !res.data.rows[0].path) {
                  reject("No data")
                }
                resolve(res.data.rows[0])
              })
              .catch((e) => {
                console.log(e)
                reject(e)
              })
          })
          .catch((e) => {
            console.log(e)
            reject(e)
          })
      })
    })
  }

  getImage = (path) => {
    return new Promise((resolve, reject) => {
      let baseUrl = this.cartApiBaseUrl
      this.accessToken().then((token) => {
        axios
          .get(`${baseUrl}${path}`, {
            headers: this.headers(token, { Accept: "image/png" }),
            responseType: "blob",
          })
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            console.log(e)
          })
      })
    })
  }

  fileConvertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (err) => {
        reject(err)
      }
    })
  }

  readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onabort = () => reject("abort")
      reader.onerror = (e) => reject(e)
      reader.onload = (e) => {
        const binaryStr = e.target.result
        console.log(binaryStr)
        resolve(binaryStr)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  getGakku = () => this._get("gakku")

  getAverageYosoku = (
    viewData,
    rangeType,
    bounds,
    schoolArea,
    modeColumn = "global"
  ) => {
    return new Promise((resolve, reject) => {
      this._getOne("average_yosoku", {
        range_type: rangeType,
        bounds: this._getBounds(bounds),
        mode_column: modeColumn,
        school_area: schoolArea ?? null,
        view_data: viewData,
      })
        .then((res) => {
          console.log("getAverageYosoku", "response", res)
          resolve(res)
        })
        .catch((e) => {
          console.log("getAverageYosoku", "errror", e)
          reject(e)
        })
    })
  }

  getTotalCount = (rangeType, bounds, filter) =>
    this._getOne("total_count", {
      range_type: rangeType,
      bounds: this._getBounds(bounds),
      filter: filter,
    })

  getAgePieChartData = (filter, rangeType, bounds, limit = 5) =>
    this._get("age_pie_chart", {
      filter,
      range_type: rangeType,
      bounds: this._getBounds(bounds),
      limit: limit,
    })

  getInjuryPieChartData = (filter, rangeType, bounds, limit = 5) => {
    return new Promise((resolve, reject) => {
      this._get(`injury_pie_chart`, {
        filter,
        range_type: rangeType,
        bounds: this._getBounds(bounds),
        limit,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getPredictDouroData = (
    mode,
    rangeType,
    bounds,
    count = null,
    min = 0.1,
    max = 0.9
  ) =>
    this._get("predict_douro", {
      mode,
      range_type: rangeType,
      bounds: this._getBounds(bounds),
      count,
      min,
      max,
    })

  getPredictKosatenData = (
    mode,
    rangeType,
    bounds,
    count = null,
    min = 0.1,
    max = 0.9
  ) =>
    this._get("predict_kosaten", {
      mode,
      range_type: rangeType,
      bounds: this._getBounds(bounds),
      count,
      min,
      max,
    })

  getDouroPredictionList = (request, options) => {
    return new Promise((resolve, reject) => {
      this._req("get", "douro_prediction_list", {
        params: request,
        rawResponse: true,
      })
        .then((res) => {
          console.log(res)
          resolve({
            rowCount: res.rowcount,
            rowData: res.rows,
          })
        })
        .catch(reject)
    })
  }

  getKosatenPredictionList = (request) => {
    return new Promise((resolve, reject) => {
      this._req("get", "kosaten_prediction_list", {
        params: request,
        rawResponse: true,
      })
        .then((res) => {
          console.log(res)
          resolve({
            rowCount: res.rowcount,
            rowData: res.rows,
          })
        })
        .catch(reject)
    })
  }

  getJissekiDetailData = (uuid, mode = "global") =>
    this._getOne("jisseki_detail", {
      uuid,
      mode,
    })

  getKosatenDetailData = (uuid, mode = "global") =>
    this._getOne("kosaten_detail", {
      uuid,
      mode,
    })

  getDouroDetailData = (uuid, mode = "global") =>
    this._getOne("douro_detail", {
      uuid,
      mode,
    })

  getNotificationMessage = () => this._getOne("notification_message")

  getEnv = () => this._getOne("env")

  getVectorTile = (query) => {
    return new Promise((resolve, reject) => {
      this._postOne("map", {
        query,
        cartoCss: {},
      })
        .then((res) => {
          console.log("[PostMap]", "response", res)
          let url = `${process.env.REACT_APP_CARTO_API_BASEURL}/map/${res.id}/{z}/{x}/{y}.mvt`
          resolve([url])
        })
        .catch(reject)
    })
  }

  //getMemoThreads = (type, id) => this._get(`memo/threads/${type}/${id}`)
  getMemoThreads = (type, id) => {
    return new Promise((resolve, reject) => {
      this._get(`memo/threads/${type}/${id}`)
        .then((res) => {
          resolve(
            res.map((r) => {
              return {
                ...r,
                title: r.title ? decodeURI(r.title) : null,
              }
            })
          )
        })
        .catch((e) => {})
    })
  }

  setMemoThread = (type, uuid, title, options) => {
    return new Promise((resolve, reject) => {
      this._postOne("memo/thread", {
        type,
        uuid,
        title: title ? encodeURI(title) : null,
        options,
      })
        .then((row) => {
          resolve({ ...row, title: decodeURI(title) })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  setMemoOpen = (commentId, open, options) => {
    return new Promise((resolve, reject) => {
      this._putOne(`memo/open/${commentId}`, {
        open,
      })
        .then((res) => {
          resolve({
            ...res,
            message: decodeURI(res.message ?? ""),
            title: decodeURI(res.title),
          })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  updateMemoThread = (threadId, title, options) =>
    this._putOne(`memo/thread/${threadId}`, {
      title: title ? encodeURI(title) : null,
      options,
    })

  deleteThread = (threadId) => this._deleteOne(`memo/thread/${threadId}`)

  getMemoComments = (threadId) => {
    return new Promise((resolve, reject) => {
      if (!threadId) {
        resolve([])
      }
      this._get(`memo/comments/${threadId}`)
        .then((res) => {
          resolve(
            res.map((r) => {
              return {
                ...r,
                message: decodeURI(r.message ?? ""),
                files: r.files ? JSON.parse(r.files) : null,
              }
            })
          )
        })
        .catch(reject)
    })
  }

  setMemoComment = (threadId, message, files, options) =>
    this._postOne(`memo/comment/${threadId}`, {
      message: encodeURI(message ?? ""),
      files: JSON.stringify(files),
      options,
    })

  updateMemoComment = (commentId, message, files, options) =>
    this._putOne(`memo/comment/${commentId}`, {
      message: encodeURI(message ?? ""),
      files: JSON.stringify(files),
      options,
    })

  deleteMemoComment = (commentId) =>
    this._deleteOne(`memo/comment/${commentId}`)

  deleteMemoThread = (threadId) => this._deleteOne(`memo/thread/${threadId}`)

  getDouroRiskHist = (uuid, mode) =>
    this._get(`risk_hist_douro/${uuid}`, {
      mode,
    })
  getKosatenRiskHist = (uuid, mode) =>
    this._get(`risk_hist_kosaten/${uuid}`, {
      mode,
    })

  sendContact = (type, email, body) => {
    return new Promise((resolve, reject) => {
      this._postOne("contact", {
        type: encodeURI(type),
        email: encodeURI(email),
        body: encodeURI(body),
      })
        .then(resolve)
        .catch(reject)
    })
  }

  _get = (path, params = null, headers = null) => {
    return this._req("get", path, { params }, headers)
  }

  _getOne = (path, params = null, headers = null) => {
    return this._reqOne("get", path, { params }, headers)
  }

  _post = (path, data = null, params = null, headers = null) => {
    return this._req("post", path, { params, data }, headers)
  }

  _postOne = (path, data = null, params = null, headers = null) => {
    return this._reqOne("post", path, { params, data }, headers)
  }

  _put = (path, data = null, params = null, headers = null) => {
    return this._req("put", path, { params, data }, headers)
  }

  _putOne = (path, data = null, params = null, headers = null) => {
    return this._reqOne("put", path, { params, data }, headers)
  }

  _delete = (path, data = null, params = null, headers = null) => {
    return this._req("delete", path, { params, data }, headers)
  }

  _deleteOne = (path, data = null, params = null, headers = null) => {
    return this._reqOne("delete", path, { params, data }, headers)
  }

  _req = (method, path, options = null, headers = null) => {
    return new Promise((resolve, reject) => {
      let url = options?.params?.auth
        ? `${this.authApiBaseUrl}/${path}`
        : `${this.cartApiBaseUrl}/${path}`

      delete options?.params?.auth

      this.accessToken()
        .then((token) => {
          axios({
            url,
            method,
            headers: this.headers(token, headers),
            ...{ ...options, params: this._encodeParams(options.params) },
          })
            .then((res) => {
              if (res.status !== 200) {
                return reject(res.statusText)
              }
              if (!res.data) {
                return resolve(null)
              }
              if (!res.data.result) {
                return reject(res.data.rows)
              }

              if (options?.rawResponse) {
                resolve(res.data)
              } else {
                resolve(res.data.rows)
              }
            })
            .catch((e) => {
              reject(e)
            })
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  _encodeParams = (params) => {
    if (!params) {
      return null
    }
    return Object.fromEntries(
      Object.keys(params).map((k) => {
        switch (typeof params[k]) {
          case "object":
            return [k, encodeURI(JSON.stringify(params[k]))]
          default:
            break
        }
        return [k, encodeURI(params[k])]
      })
    )
  }

  _reqOne = (method, path, options = null, headers = null) => {
    return new Promise((resolve, reject) => {
      this._req(method, path, options, headers)
        .then((res) => {
          if (!res || res.length === 0) {
            return resolve(null)
          }
          return resolve(res[0])
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  _getBounds = (bounds) => {
    return JSON.stringify(bounds)
    // console.log("Bounds", bounds)
    // if (!bounds) {
    //   return null
    // }
    // return JSON.stringify(bounds.map((v) => [v.lng, v.lat]))
  }
})()
