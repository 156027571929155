import { useReducer } from "react"
import reducer, { ActionType } from "./reducer"
import initialState from "./state"

const UseMemoViewData = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  })

  const setThread = (value) => {
    dispatch({ type: ActionType.SetThread, value: value })
  }

  const setThreadId = (value) => {
    dispatch({ type: ActionType.SetThreadId, value: value })
  }

  const setThreadTitle = (value) => {
    dispatch({ type: ActionType.SetThreadTitle, value: value })
  }

  const dataUpdated = () => {
    dispatch({ type: ActionType.UpdateLastUpdated })
  }

  const clearData = () => {
    dispatch({ type: ActionType.ClearData })
  }

  return {
    state,
    setThread,
    setThreadId,
    setThreadTitle,
    dataUpdated,
    clearData,
  }
}

export default UseMemoViewData
