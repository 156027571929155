export const ActionType = {
  SetViewType: "SET_VIEW_TYPE",
}

const ListViewDataReducer = (state, action) => {
  switch (action.type) {
    case ActionType.SetViewType:
      return { ...state, viewType: action.value }
    default:
      break
  }

  return state
}

export default ListViewDataReducer
