import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material"
import { RootDataContext } from "../../index"
import APIManager from "../../../../manager/api"
import FileLoading from "../../../../resources/image_loading.gif"
import dayjs from "dayjs"
import { useAuth0 } from "@auth0/auth0-react"
import { MemoDataContext } from "../index"
import parse, { domToReact } from "html-react-parser"

const styles = {
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    position: "relative",
    margin: "16px 8px",
    border: "solid #c9c9c9",
    borderWidth: "0 0 1px 0",
  },
  imageBox: {},
  image: { width: "30px", borderRadius: "4px" },
  body: { display: "flex", flexDirection: "column", gap: "4px" },
  userName: { fontWeight: "700", fontSize: "1rem" },
  header: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
  },
  postTime: { fontSize: "0.93rem" },
  fileBox: { overflowX: "auto", overflowY: "hidden" },
  file: { height: "80px" },
  filePreviewImage: { height: "80px", cursor: "pointer" },
  controlBox: {
    position: "absolute",
    right: "8px",
    top: "8px",
    backgroundColor: "#eee",
    borderRadius: "8px",
    padding: "0 8px",
    boxShadow: "0 0 3px #c8c8c8",
  },
  openLabel: {
    fontSize: "12px",
    fontStyle: "normal",
    border: "1px solid #666",
    borderRadius: "4px",
    padding: "2px 8px",
    marginLeft: "8px",
    whiteSpace: "nowrap",
  },
  openLabel_close: {
    backgroundColor: "#999",
    color: "#fff",
  },
  openLabel_open_other: {
    cursor: "normal",
    backgroundColor: "white",
    color: "#006c60",
  },
  openLabel_open_owner: {
    cursor: "pointer",
    backgroundColor: "#006c60",
    color: "white",
  },
}

const weekDay = ["日", "月", "火", "水", "木", "金", "土"]
const MemoCommentMemoView = (props) => {
  const { openStateDialog, setCommentPreviewImage } =
    useContext(RootDataContext)
  const {
    state: memoState,
    dataUpdated,
    setThread,
  } = useContext(MemoDataContext)
  const { state } = useContext(RootDataContext)
  const [showControl, setShowControl] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [fileDataList, setFileDataList] = useState()

  const data = useMemo(() => {
    console.log("[Update]", "props.data", props.data)

    let pTime = dayjs(props.data.created_at_jst)
    let uTime = dayjs(props.data.updated_at_jst)

    return {
      commentId: props.data.comment_id,
      message: props.data.message,
      files: props.data.files,
      isOpen: props.data.is_open,

      createTenantName: props.data.created_tenant_name,
      createGroupName: props.data.created_group_name,
      createUserName: props.data.created_user_name,
      createTenantId: props.data.created_tenant_id,
      createUserImage: props.data.created_user_image,
      createTime: pTime.format(
        `YYYY年M月D日(${weekDay[parseInt(pTime.format("d"))]}) H時m分`
      ),

      isUpdated:
        props.data.updated_at_jst &&
        props.data.created_at_jst !== props.data.updated_at_jst,
      updateTenantName: props.data.tenant_name,
      updateGroupName: props.data.group_name,
      updateTenantId: props.data.tenant_id,
      updateUserName: props.data.user_name,
      updateTime: uTime.format(
        `YYYY年M月D日(${weekDay[parseInt(uTime.format("d"))]}) H時m分`
      ),
      updateImage: props.data.last_edited_user_image,
      isEditable: props.data.is_editable,
    }
  }, [memoState.update, props.data])

  useEffect(() => {
    if ((data?.files ?? []).length === 0) {
      return
    }

    Promise.all(
      data.files.map((f) => {
        return new Promise((resolve, reject) => {
          APIManager.getImage(f.path)
            .then((res) => {
              let url = window.URL ?? window.webkitURL
              resolve({ src: url.createObjectURL(res), path: f.path })
            })
            .catch((e) => {
              reject(e)
            })
        })
      })
    )
      .then((result) => {
        setFileDataList(result)
      })
      .catch((e) => {
        alert("画像の取得に失敗しました")
      })
  }, [data?.files])

  useEffect(() => {
    console.log("[UpdateData]", data)
  }, [data])

  const openLabelStyle = useMemo(() => {
    if (!data) {
      return styles.openLabel
    }

    if (!data.isOpen) {
      return {
        ...styles.openLabel,
        ...styles.openLabel_close,
        cursor: props.idx > 0 ? "pointer" : "normal",
      }
    }
    if (data.isEditable) {
      return {
        ...styles.openLabel,
        ...styles.openLabel_open_owner,
        cursor: props.idx > 0 ? "pointer" : "normal",
      }
    }
    return { ...styles.openLabel, ...styles.openLabel_open_other, cursor: "default" }
  }, [data, props.idx])

  const onDeleteThread = () => {
    openStateDialog({
      title: "このスレッドを本当に削除しますか",
      content: (
        <>
          <Typography style={{ color: "#b40000", fontWeight: 700 }}>
            スレッドを削除しようとしています
          </Typography>
          <Typography>この操作は元に戻すことが出来ません</Typography>
        </>
      ),
      actions: [
        {
          title: "キャンセル",
          onClick: () => {
            console.log("キャンセル")
          },
        },
        {
          title: "スレッドを削除",
          color: "secondary",
          onClick: () => {
            APIManager.deleteMemoThread(memoState.threadId)
              .then(() => {
                setThread(null)
              })
              .catch((e) => {
                console.log(e)
              })
          },
        },
      ],
    })
  }

  const onDeleteComment = () => {
    openStateDialog({
      title: "このメッセージを本当に削除しますか",
      content: <Typography>この操作は元に戻すことが出来ません</Typography>,
      actions: [
        {
          title: "キャンセル",
          onClick: () => {
            console.log("キャンセル")
          },
        },
        {
          title: "削除",
          color: "secondary",
          onClick: () => {
            APIManager.deleteMemoComment(data.commentId)
              .then(() => {
                dataUpdated()
              })
              .catch((e) => {
                console.log(e)
              })
          },
        },
      ],
    })
  }

  const onDeleteSubmit = () => {
    console.log("submit")
  }

  const toggleOpen = () => {
    if (data.idx === 0) { return }
    console.log(state.user, data)
    if (!data.isEditable) {
      return
    }

    openStateDialog({
      title: `スレッドの${data.isOpen ? "非公開" : "公開"}`,
      content: (
        <Typography>
          このスレッドを{data.isOpen ? "非公開" : "公開"}にしますか
        </Typography>
      ),
      actions: [
        {
          title: "キャンセル",
          onClick: () => {
            console.log("キャンセル")
          },
        },
        {
          title: data.isOpen ? "非公開" : "公開",
          color: data.isOpen ? "secondary" : "primary",
          onClick: () => {
            APIManager.setMemoOpen(data.commentId, !data.isOpen, {
              type: "thread",
            })
              .then((res) => {
                dataUpdated()
              })
              .catch((e) => {
                console.log(e)
              })
          },
        },
      ],
    })
  }

  return (
    <Box
      sx={styles.root}
      onMouseOver={() => {
        if (data.isEditable) {
          setShowControl(true)
        }
      }}
      onMouseLeave={() => {
        setShowControl(false)
      }}
    >
      <Box sx={styles.imageBox}>
        <img style={styles.image} src={data.userImage} />
      </Box>
      <Box sx={styles.body}>
        <Box>
          <Box>
            <Box sx={styles.header}>
              <Box sx={styles.userName}>
                {data.createGroupName} {data.createUserName}
              </Box>
              <Box sx={styles.postTime}>{data.createTime}</Box>
            </Box>
            {data.isUpdated && (
                <Box sx={{ ...styles.header, fontSize: "11px" }}>
                  (編集済み
                  <Box sx={{ ...styles.userName, fontSize: "11px" }}>
                    {data.updateGroupName} {data.updateUserName}
                  </Box>
                  <Box sx={{ ...styles.postTime, fontSize: "11px" }}>
                    {data.updateTime}
                  </Box>
                  )
                </Box>
            )}
          </Box>
          <Box>
            {memoState.thread?.is_open && (
                <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      marginBottom: "8px",
                    }}
                >
                  <Box
                      style={openLabelStyle}
                      onClick={() => {
                        toggleOpen()
                      }}
                  >
                    {data.isOpen ? "公開" : "非公開"}
                  </Box>
                </Box>
            )}
          </Box>
        </Box>
        <Box sx={styles.message}>
          {parse(data.message.replaceAll("\n", "<br />"))}
        </Box>
        <Box sx={styles.fileBox}>
          {fileDataList?.map((d, i) => {
            return (
              <Box
                key={d.path}
                sx={styles.filePreviewBox}
                onClick={() => {
                  if (d.src) {
                    setCommentPreviewImage(d.src)
                  }
                }}
              >
                <img
                  src={d.src ?? FileLoading}
                  style={styles.filePreviewImage}
                />
              </Box>
            )
          })}
        </Box>

      </Box>
      {showControl && (
        <Box sx={styles.controlBox}>
          <IconButton onClick={props.onEdit} size="small">
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              props.idx > 0 ? onDeleteComment() : onDeleteThread()
            }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      <Dialog
        fullScreen={true}
        oepn={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>本当に削除しますか</DialogTitle>
        <DialogContent>この操作は元に戻せません</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowDeleteDialog(false)}>
            キャンセル
          </Button>
          <Button color="secondary" onClick={() => onDeleteSubmit()}>
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

MemoCommentMemoView.propTypes = {
  data: PropTypes.object,
  idx: PropTypes.number,
  onEdit: PropTypes.func,
}

export default MemoCommentMemoView
