import React, { useContext, useMemo, useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { RootDataContext } from "../../../index"
import { MapDetailInfoDefaultStyles } from "../../../../../styles"

const styles = { ...MapDetailInfoDefaultStyles }

// eslint-disable-line
// eslint-disable-next-line react/display-name
const MapDetailInfoKosatenDetailView = React.memo((props) => {
  const { state, setShowMemoData } = useContext(RootDataContext)

  const data = useMemo(() => props?.data ?? {}, [props.data, state.predictionMode])


  const suddenAcceleration = useMemo(() => {
    if ((data.sudden_acceleration ?? 0) < 2) {
      return "--"
    }
    return data.sudden_acceleration
  }, [data.sudden_acceleration])

  const suddenDeceleration = useMemo(() => {
    if ((data.sudden_deceleration ?? 0) < 2) {
      return "--"
    }
    return data.sudden_deceleration
  }, [data.sudden_deceleration])

  const suddenHandleLeft = useMemo(() => {
    if ((data.sudden_handle_left ?? 0) < 2) {
      return "--"
    }
    return data.sudden_handle_left
  }, [data.sudden_handle_left])

  const suddenHandleRight = useMemo(() => {
    if ((data.sudden_handle_right ?? 0) < 2) {
      return "--"
    }
    return data.sudden_handle_right
  }, [data.sudden_handle_right])

  const wobble = useMemo(() => {
    if ((data.wobble ?? 0) < 2) {
      return "--"
    }
    return data.wobble
  }, [data.wobble])

  const forwardCollisionWarning = useMemo(() => {
    if ((data.forward_collision_warning ?? 0) < 2) {
      return "--"
    }
    return data.forward_collision_warning
  }, [data.forward_collision_warning])

  const laneDeviationWarningLeft = useMemo(() => {
    if ((data.lane_deviation_warning_left ?? 0) < 2) {
      return "--"
    }
    return data.lane_deviation_warning_left
  }, [data.lane_deviation_warning_left])

  const laneDeviationWarningRight = useMemo(() => {
    if ((data.lane_deviation_warning_right ?? 0) < 2) {
      return "--"
    }
    return data.lane_deviation_warning_right
  }, [data.lane_deviation_warning_right])


  return (
    <Box sx={{ backgroundColor: "white" }}>
      <Typography>交差点詳細データ</Typography>
      <Box>
        <table style={styles.table}>
          <tr>
            <th style={styles.th}>事故発生リスク</th>
            <td style={styles.td}>{data.risk ?? "--"}</td>
          </tr>
          <tr>
            <th style={styles.th}>事故発生件数</th>
            <td style={styles.td}>{data.acc ?? "--"}</td>
          </tr>
          <tr>
            <th style={styles.th}>法定速度</th>
            <td style={styles.td}>{data.speed ?? "--"}</td>
          </tr>
          <tr>
            <th style={styles.th}>走行速度</th>
            <td style={styles.td}>{data.speed_median?.toFixed(1) ?? "--"}</td>
          </tr>
          <tr>
            <th style={styles.th}>車線数</th>
            <td style={styles.td}>{data.lane_count ?? "--"}</td>
          </tr>
          <tr>
            <th style={styles.th}>急加速</th>
            <td style={styles.td}>{suddenAcceleration}</td>
          </tr>
          <tr>
            <th style={styles.th}>急減速</th>
            <td style={styles.td}>{suddenDeceleration}</td>
          </tr>
          <tr>
            <th style={styles.th}>急ハンドル（左）</th>
            <td style={styles.td}>{suddenHandleLeft}</td>
          </tr>
          <tr>
            <th style={styles.th}>急ハンドル（右）</th>
            <td style={styles.td}>{suddenHandleRight}</td>
          </tr>
          <tr>
            <th style={styles.th}>ふらつき</th>
            <td style={styles.td}>{wobble}</td>
          </tr>
          <tr>
            <th style={styles.th}>前方衝突警告</th>
            <td style={styles.td}>{forwardCollisionWarning}</td>
          </tr>
          <tr>
            <th style={styles.th}>車線逸脱アラート（左）</th>
            <td style={styles.td}>{laneDeviationWarningLeft}</td>
          </tr>
          <tr>
            <th style={styles.th}>車線逸脱アラート（右）</th>
            <td style={styles.td}>{laneDeviationWarningRight}</td>
          </tr>
        </table>
      </Box>
      <Box sx={styles.commentBox}>
        <Button
          variant="contained"
          style={styles.commentButton}
          onClick={() => {
            setShowMemoData({ type: "kosaten", uuid: data.uuid })
          }}
        >
          メモ確認・作成{data.comments && `(${data.comments ?? 0})`}
        </Button>
      </Box>
    </Box>
  )
})

MapDetailInfoKosatenDetailView.propTypes = {
  data: PropTypes.object,
}

export default MapDetailInfoKosatenDetailView
