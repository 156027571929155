import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { RootDataContext } from "../../../index"
import DataManager from "../../../../../manager/data"
import APIManager from "../../../../../manager/api"

const SOURCE_NAME = "gyoseikai"
const LAYER_NAME = "gyoseikai_layer"
const GyoseikaiLayer = (props) => {
  const { state } = useContext(RootDataContext)
  const map = useMemo(() => props.map, [props.map])
  const [tiles, setTiles] = useState()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!state.user) { return }

    APIManager.getVectorTile(DataManager.selectQueryGyoseikai(state.user))
      .then((res) => {
        setTiles(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [state.user])

  useEffect(() => {
    if (!map || !tiles) {
      return
    }

    let source = map.getSource(SOURCE_NAME)
    if (source) {
      source.setTiles(tiles)
      return
    }

    map.addSource(SOURCE_NAME, {
      type: "vector",
      tiles: tiles,
    })

    map.addLayer({
      id: `${LAYER_NAME}_line`,
      type: "line",
      source: SOURCE_NAME,
      "source-layer": "layer0",
      minzoom: 10,
      maxzoom: 22,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#6c6c6c",
        "line-opacity": 0.5,
        "line-width": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          1.5,
          14,
          2.0,
          18,
          5,
        ],
      },
    })
    setInitialized(true)
    props.onLoad && props.onLoad()
  }, [map, tiles])

  useEffect(() => {
    if (!map || !initialized) {
      return
    }

    map.setLayoutProperty(
      `${LAYER_NAME}_line`,
      "visibility",
      state.viewData.includes("行政界") ? "visible" : "none"
    )
  }, [map, initialized, state.viewData])

  return null
}

export const Order = (map, beforeLayer) => {
  map.moveLayer(`${LAYER_NAME}_line`, beforeLayer)
}

export const MAJOR_LAYER_NAME =  `${LAYER_NAME}_line`

GyoseikaiLayer.propTypes = {
  map: PropTypes.any,
  onLoad: PropTypes.func,
}

export default GyoseikaiLayer
