import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

const styles = {
  root: {
    position: "relative",
    width: "200px",
    height: "70px",
    backgroundColor: "white",
    boxShadow: "2px 2px 3px rgba(0,0,0,.4)",
    borderRadius: "4px",
  },
  title: {
    position: "relative",
    margin: "6px 0 3px 12px !important",
  },
  circle: {
    backgroundColor: "#422fbc",
    width: "14px",
    height: "14px",
    marginLeft: "16px",
    borderRadius: "7px",
    marginTop: "8px",
  },
}

const RootMapJissekiLegends = (props) => {

  return (
    <Box sx={{...styles.root, ...props.sx}}>
      <Typography sx={styles.title} variant="subtitle2">
        事故実績
      </Typography>
      <Box sx={styles.circle} />
    </Box>
  )
}

RootMapJissekiLegends.propTypes = {
  sx: PropTypes.object,
}

export default RootMapJissekiLegends
